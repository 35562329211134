import Vue from 'vue'
import Vuex from 'vuex'
import {
  tripCouponStocksList,
  creatTripCouponStocks,
  queryTripCouponStocks,
  editTripCouponStocks,
} from '@/api/tripCouponStocks'
Vue.use(Vuex)
export default {
  namespaced: true,
  state: {
    tripCouponStocksListVal: {}, // 出行券库存列表搜索条件
    allTripCouponStocks: [], // 出行券库存列表
  },
  mutations: {
    setState(state, payload) {
      state[payload.key] = payload.values
    },
  },
  actions: {
    changeTripCouponStocksListSearch({ commit }, payload) {
      commit({ type: 'setState', key: 'tripCouponStocksListVal', values: payload })
    },
    // 出行券列表
    getTripCouponStocksList({ commit, state }, payload) {
      tripCouponStocksList(payload).then((res) => {
        commit({ type: 'setState', key: 'allTripCouponStocks', values: res.data.data })
      })
    },
    // 添加出行券库存
    addTripCouponStocks({ commit, state }, payload) {
      return creatTripCouponStocks(payload)
    },
    // 查询出行券库存
    getTripCouponStocks({ commit, state }, payload) {
      return queryTripCouponStocks(payload)
    },
    // 编辑出行券库存
    changeTripCouponStocks({ commit, state }, payload) {
      return editTripCouponStocks(payload)
    },
  },
}
