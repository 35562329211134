// 按钮权限
export default {
  // 客户信息模块按钮
  customerInfo: {
    // 修改客户信息
    customerInfoBtnStatus: [
      'initial.operator.approved', // [审核] 订单审核通过
      'prepare_car.operator.succeeded', // [备车] 运营人员备车成功
      'prepare_car.operator.cancelled', // [备车] 运营人员已取消备车
      'prepare_car.system.cancelled', // [备车] 系统已取消备车
      'prepare_car.operator.failed', // [备车] 运营人员备车失败
      'prepare_car.system.failed', // [备车] 系统备车失败
      'delivery.operator.request_cancel_carrier', // [交付] 运营人员申请取消送车承运人
      'delivery.operator.carrier_cancelled', //	[交付] 运营人员已取消送车承运人
      'delivery.carrier.cancelled', // [交付] 送车承运人取消
      'delivery.carrier.delivered', // [交付] 承运人已交车
      'delivery.customer.collected', // [自行取车] 用户已取车
      'in_use.customer.lease_renewal', // [用车] 用户延时还车
      'in_use.customer.lease_renewal_order_paid', // [用车] 用户延时还车订单支付成功
      'in_use.customer.lease_renewal_order_created', // [用车] 用户创建延时还车订单成功
      'in_use.customer.lease_renewal_order_canceled', // [用车] 用户取消延时还车
      'return.customer.awaiting_return', // [还车] 待还车，用户主动提交停止用车
      'return.operator.request_cancel_carrier', // [还车] 运营人员申请取消取车承运人
      'return.operator.carrier_cancelled', // [还车] 运营人员已取消取车承运人
      'return.carrier.cancelled', // [还车] 送车承运人取消
    ],
    // 重新识别
    reidentification: [
      'initial.client.created', // [创建] 创建订单成功
      'initial.client.modified', // [审核] 订单被接口调用方修改等待重新审核
      'initial.partner.approved', // [审核] 订单预审核通过
    ],
    // 补录证件信息
    recordIDInfo: [
      'initial.client.created', // [创建] 创建订单成功
      'initial.client.modified', // [审核] 订单被接口调用方修改等待重新审核
      'initial.partner.approved', // [审核] 订单预审核通过
    ],
    // 审核通过
    approved: [
      'initial.client.created', // [创建] 创建订单成功
      'initial.client.modified', // [审核] 订单被接口调用方修改等待重新审核
      'initial.partner.approved', // [审核] 订单预审核通过
    ],
    // 审核不通过
    rejected: [
      'initial.client.created', // [创建] 创建订单成功
      'initial.client.modified', // [审核] 订单被接口调用方修改等待重新审核
      'initial.partner.approved', // [审核] 订单预审核通过
    ],
    // 取消用户订单
    cancelOrder: [
      'initial.client.created', // [创建] 创建订单成功
      'initial.client.modified', // [审核] 订单被接口调用方修改等待重新审核
      'initial.partner.approved', // [审核] 订单预审核通过
    ],
  },
  // 客户信息弹框可修改字段
  customerInfoModal: {
    // 修改用车时间/地址
    editCustomerDeliveryInfo: [
      'initial.operator.approved', // [审核] 订单审核通过
      'prepare_car.operator.succeeded', // [备车] 运营人员备车成功
      'prepare_car.operator.cancelled', // [备车] 运营人员已取消备车
      'prepare_car.system.cancelled', // [备车] 系统已取消备车
      'prepare_car.operator.failed', // [备车] 运营人员备车失败
      'prepare_car.system.failed', // [备车] 系统备车失败
      'delivery.operator.request_cancel_carrier', // [交付] 运营人员申请取消送车承运人
      'delivery.operator.carrier_cancelled', // [交付] 运营人员已取消送车承运人
      'delivery.carrier.cancelled', // [交付] 送车承运人取消
    ],
    // 修改还车时间/地址
    editCustomerReturnInfo: [
      'delivery.carrier.delivered', // [交付] 承运人已交车
      'delivery.customer.collected', // [自行取车] 用户已取车
      'in_use.customer.lease_renewal', // [用车] 用户延时还车
      'in_use.customer.lease_renewal_order_paid', // [用车] 用户延时还车订单支付成功
      'in_use.customer.lease_renewal_order_created', // [用车] 用户创建延时还车订单成功
      'in_use.customer.lease_renewal_order_canceled', // [用车] 用户取消延时还车
      'return.customer.awaiting_return', // [还车] 待还车，用户主动提交停止用车
      'return.operator.request_cancel_carrier', // [还车] 运营人员申请取消取车承运人
      'return.operator.carrier_cancelled', // [还车] 运营人员已取消取车承运人
      'return.carrier.cancelled', // [还车] 送车承运人取消
    ],
  },
  // 备车信息模块按钮
  prepareCarInfo: {
    // 提交
    submit: [
      'initial.operator.approved', // [审核] 订单审核通过
      'prepare_car.operator.cancelled', // [备车] 运营人员已取消备车
      'prepare_car.system.cancelled', // [备车] 系统已取消备车
      'prepare_car.operator.failed', // [备车] 运营人员备车失败
      'prepare_car.system.failed', // [备车] 系统备车失败
    ],
    // 修改
    modify: [
      'prepare_car.operator.succeeded', //	[备车] 运营人员备车成功
      'prepare_car.system.succeeded', //	[备车] 系统备车成功
      'delivery.operator.carrier_order_created', //	[交付] 运营人员已创建承运人订单
      'delivery.operator.request_cancel_carrier', //	[交付] 运营人员申请取消送车承运人
      'delivery.operator.carrier_cancelled', //	[交付] 运营人员已取消送车承运人
      'delivery.carrier.cancelled', //	[交付] 送车承运人取消
      'delivery.operator.carrier_planned', //	[交付] 运营人员已安排送车承运人
      'delivery.carrier.going_to_collection_point', //	[交付] 承运人正在取车途中
      'delivery.carrier.arrival_at_collection_point', //	[交付] 承运人到达取车点
    ],
    // 取消备车
    cancelVehicle: [
      'prepare_car.operator.succeeded', // [备车] 运营人员备车成功
      'prepare_car.system.succeeded', // [备车] 系统备车成功
      'delivery.operator.request_cancel_carrier', // [交付] 运营人员申请取消送车承运人
      'delivery.operator.carrier_cancelled', // [交付] 运营人员已取消送车承运人
      'delivery.carrier.cancelled', // [交付] 送车承运人取消
    ],
    // 派发出行券
    sendCoupons: [
      'initial.operator.approved', // [审核] 订单审核通过
      'prepare_car.operator.cancelled', // [备车] 运营人员已取消备车
      'prepare_car.system.cancelled', // [备车] 系统已取消备车
      'prepare_car.operator.failed', // [备车] 运营人员备车失败
      'prepare_car.system.failed', // [备车] 系统备车失败
      'delivery.operator.request_cancel_carrier', // [交付] 运营人员申请取消送车承运人
      'delivery.operator.carrier_cancelled', // [交付] 运营人员已取消送车承运人
      'delivery.carrier.cancelled', // [交付] 送车承运人取消
    ],
    // 取消用户订单
    cancelOrder: [
      'initial.operator.approved', //	[审核] 订单审核通过
      'prepare_car.operator.succeeded', //	[备车] 运营人员备车成功
      'prepare_car.system.succeeded', //	[备车] 系统备车成功
      'prepare_car.operator.cancelled', //	[备车] 运营人员已取消备车
      'prepare_car.system.cancelled', //	[备车] 系统已取消备车
      'prepare_car.operator.failed', //	[备车] 运营人员备车失败
      'prepare_car.system.failed', //	[备车] 系统备车失败
    ],
  },
  // 交车信息按钮
  useCarInfo: {
    // 提交
    submit: [
      'initial.operator.approved', // [审核] 订单审核通过
      'prepare_car.operator.succeeded', // [备车] 运营人员备车成功
      'prepare_car.system.succeeded', // [备车] 系统备车成功
      'prepare_car.operator.cancelled', // [备车] 运营人员已取消备车
      'prepare_car.system.cancelled', // [备车] 系统已取消备车
      'prepare_car.operator.failed', // [备车] 运营人员备车失败
      'prepare_car.system.failed', // [备车] 系统备车失败
      'delivery.operator.request_cancel_carrier', // [交付] 运营人员申请取消送车承运人
      'delivery.operator.carrier_cancelled', // [交付] 运营人员已取消送车承运人
      'delivery.carrier.cancelled', // [交付] 送车承运人取消
    ],
    // 取消代驾订单
    cancelDriving: [
      'delivery.operator.carrier_order_created', //	[交付] 运营人员已创建承运人订单
      'delivery.operator.carrier_planned', //	[交付] 运营人员已安排送车承运人
      'delivery.carrier.going_to_collection_point', // [交付] 承运人正在取车途中
    ],
    // 补录代驾信息
    drivingInfo: [
      'delivery.carrier.going_to_collection_point', // [交付] 承运人正在取车途中
      'delivery.carrier.arrival_at_collection_point', // [交付] 承运人到达取车点	司机到达取车点
    ],
    // 用户拒收
    customerRefunded: [
      'delivery.carrier.arrival_at_collection_point', // [交付] 承运人到达取车点	司机到达取车点
      'delivery.carrier.collected', // [交付] 承运人取得车辆	司机取得车辆
      'delivery.carrier.in_transit', // [交付] 承运人开始配送/在途中	司机开始送车
      'delivery.carrier.arrival_at_delivery_point', //	[交付] 承运人将车辆送达指定地点	司机到达用户位置
      'delivery.carrier.cancelled', // [交付] 送车承运人取消
    ],
    // 取消用户订单
    cancelOrder: [
      'delivery.operator.carrier_order_created', // [交付] 运营人员已创建承运人订单
      'delivery.operator.request_cancel_carrier', // [交付] 运营人员申请取消送车承运人
      'delivery.operator.carrier_cancelled', // [交付] 运营人员已取消送车承运人
      'delivery.carrier.cancelled', // [交付] 送车承运人取消
      'delivery.operator.carrier_planned', // [交付] 运营人员已安排送车承运人
      'delivery.carrier.going_to_collection_point', // [交付] 承运人正在取车途中
    ],
    // 查看代驾信息
    checkDrivingInfo: [
      'initial.operator.cancelled', // [取消] 订单被运营人员取消
      'initial.client.cancelled', // [取消] 订单被接口调用方取消
      'delivery.operator.carrier_planned', // [交付] 运营人员已安排送车承运人
      'delivery.carrier.going_to_collection_point', // [交付] 承运人正在取车途中
      'delivery.carrier.arrival_at_collection_point', // [交付] 承运人到达取车点
      'delivery.carrier.collected', // [交付] 承运人取得车辆
      'delivery.carrier.in_transit', // [交付] 承运人开始配送/在途中
      'delivery.carrier.arrival_at_delivery_point', // [交付] 承运人将车辆送达指定地点
      'delivery.customer.refunded', // [交付] 用户拒收
      'delivery.carrier.delivered', // [交付] 承运人已交车
      'in_use.customer.lease_renewal', // [用车] 用户延时还车
      'in_use.customer.lease_renewal_order_paid', // [用车] 用户延时还车订单支付成功
      'in_use.customer.lease_renewal_order_created', // [用车] 用户创建延时还车订单成功
      'in_use.customer.lease_renewal_order_canceled', // [用车] 用户取消延时还车
      'return.customer.awaiting_return', // [还车] 待还车，用户主动提交停止用车
      'return.operator.carrier_order_created', // [还车] 运营人员已创建承运人订单
      'return.operator.request_cancel_carrier', // [还车] 运营人员申请取消取车承运人
      'return.operator.carrier_cancelled', // [还车] 运营人员已取消取车承运人
      'return.carrier.cancelled', // [还车] 送车承运人取消
      'return.operator.carrier_planned', // [还车] 运营人员已安排取车承运人
      'return.carrier.going_to_collection_point', // [还车] 承运人正在取车途中
      'return.carrier.arrival_at_collection_point', // [还车] 承运人到达取车点
      'return.carrier.in_transit', // [还车] 承运人开始返回/在途中
      'return.carrier.arrival_at_return_point', // [还车] 承运人将车辆送达还车地点
      'return.carrier.delivered', // [还车] 承运人已还车
      'return.customer.delivered', // [自行还车] 已还车
      'return.client.damage_estimate_documents_awaiting_upload', // [还车] 定损单待上传
      'return.client.damage_estimate_documents_uploaded', // [还车] 定损单上传成功
      'return.operator.damage_estimate_documents_approved', // [还车] 定损单审核通过
      'return.operator.damage_estimate_documents_rejected', // [还车] 定损单审核未通过
      'return.client.damage_estimate_documents_modified', // [还车] 定损单被接口调用方修改等待重新审核
      'return.system.damage_estimate_documents_upload_overdue', // [还车] 定损单逾期未上传
      'settlement.operator.closed', // [结算] 运营人员关单
    ],
  },
  returnCarInfo: {
    // 提交
    submit: [
      'delivery.carrier.delivered', //	[交付] 承运人已交车
      'delivery.customer.collected', //	[自行取车] 用户已取车
      'in_use.customer.lease_renewal', //	[用车] 用户延时还车
      'in_use.customer.lease_renewal_order_paid', //	[用车] 用户延时还车订单支付成功
      'in_use.customer.lease_renewal_order_created', //	[用车] 用户创建延时还车订单成功
      'in_use.customer.lease_renewal_order_canceled', //	[用车] 用户取消延时还车
      'return.customer.awaiting_return', //	[还车] 待还车，用户主动提交停止用车
      'return.operator.carrier_cancelled', //	[还车] 运营人员已取消取车承运人
      'return.carrier.cancelled', //	[还车] 送车承运人取消
      'return.operator.request_cancel_carrier', // [还车] 运营人员申请取消取车承运人
      // 'return.operator.carrier_planned', //	[还车] 运营人员已安排取车承运人
      // 'return.carrier.going_to_collection_point', //	[还车] 承运人正在取车途中
    ],
    // 取消代驾订单
    cancelDriving: [
      'return.operator.carrier_order_created', //	[还车] 运营人员已创建承运人订单
      'return.operator.carrier_planned', //	[还车] 运营人员已安排取车承运人
      'return.carrier.going_to_collection_point', //	[还车] 承运人正在取车途中
    ],
    // 查看代驾信息按钮
    drivingInfo: [
      'return.operator.carrier_planned', // [还车] 运营人员已安排取车承运人
      'return.carrier.going_to_collection_point', // [还车] 承运人正在取车途中
      'return.carrier.arrival_at_collection_point', // [还车] 承运人到达取车点
      'return.carrier.in_transit', // [还车] 承运人开始返回/在途中
      'return.carrier.arrival_at_return_point', // [还车] 承运人将车辆送达还车地点
      'return.carrier.delivered', // [还车] 承运人已还车
      'return.client.damage_estimate_documents_awaiting_upload', //	[还车] 定损单待上传
      'return.client.damage_estimate_documents_uploaded', // [还车] 定损单上传成功
      'return.operator.damage_estimate_documents_approved', // [还车] 定损单审核通过
      'return.operator.damage_estimate_documents_rejected', // [还车] 定损单审核未通过
      'return.client.damage_estimate_documents_modified', // [还车] 定损单被接口调用方修改等待重新审核
      'return.system.damage_estimate_documents_upload_overdue', // [还车] 定损单逾期未上传
      'settlement.operator.closed', //	[结算] 运营人员关单
    ],
    // 查看客服跟进记录
    callLog: [
      'delivery.carrier.delivered', //	[交付] 承运人已交车
      'delivery.customer.collected', //	[自行取车] 用户已取车
      'in_use.customer.lease_renewal', //	[用车] 用户延时还车
      'in_use.customer.lease_renewal_order_paid', //	[用车] 用户延时还车订单支付成功
      'in_use.customer.lease_renewal_order_created', //	[用车] 用户创建延时还车订单成功
      'in_use.customer.lease_renewal_order_canceled', // [用车] 用户取消延时还车
      'return.customer.awaiting_return', //	[还车] 待还车，用户主动提交停止用车
      'return.operator.carrier_order_created', //	[还车] 运营人员已创建承运人订单
      'return.operator.request_cancel_carrier', //	[还车] 运营人员申请取消取车承运人
      'return.operator.carrier_cancelled', //	[还车] 运营人员已取消取车承运人
      'return.carrier.cancelled', //	[还车] 送车承运人取消
      'return.operator.carrier_planned', //	[还车] 运营人员已安排取车承运人
      'return.carrier.going_to_collection_point', //	[还车] 承运人正在取车途中
      'return.carrier.arrival_at_collection_point', //	[还车] 承运人到达取车点
      'return.carrier.in_transit', //	[还车] 承运人开始返回/在途中
      'return.carrier.arrival_at_return_point', //	[还车] 承运人将车辆送达还车地点
      'return.carrier.delivered', //	[还车] 承运人已还车
      'return.customer.delivered', //	[自行还车] 已还车
      'return.client.damage_estimate_documents_awaiting_upload', // [还车] 定损单待上传
      'return.client.damage_estimate_documents_uploaded', // [还车] 定损单上传成功
      'return.operator.damage_estimate_documents_approved', // [还车] 定损单审核通过
      'return.operator.damage_estimate_documents_rejected', // [还车] 定损单审核未通过
      'return.client.damage_estimate_documents_modified', // [还车] 定损单被接口调用方修改等待重新审核
      'return.system.damage_estimate_documents_upload_overdue', // [还车] 定损单逾期未上传
      'settlement.operator.closed', // [结算] 运营人员关单
    ],
  },
}
