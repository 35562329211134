import { get, post } from '@/utils/request'
import globalFunction from '@/utils/globalFunction'
// 获取事故车订单详情
export const queryRepairsOrderDetail = (params) => {
  return get(`/repairs/orders/${params}`)
}
// 获取订单备忘录信息
export const queryMemorandumsDetail = (params) => {
  return get(`/repairs/orders/${params}/memorandums`)
}
// 获取订单的关联订单信息
export const queryAssociatedDetail = (params) => {
  return get(`/commons/${params.order_type}/orders/${params.id}/associateds`)
}
// 添加备忘录
export const creatMemorandumsApi = (params) => {
  return post(`/repairs/orders/${params.id}/memorandums`, { ...params })
}
// 创建代驾订单
export const repairsCarrierApi = (params) => {
  return post(`/repairs/orders/${params.id}/carriers/${params.supplier}`, { ...params })
}
// 查看明文手机号
export const queryAccessInfo = (params) => {
  return get(`/repairs/orders/${params.id}/access-info?access_type=${params.access_type}`)
}
// 查看审核记录
export const queryAuditsApi = (params) => {
  return get(`/repairs/orders/${params}/audits`)
}
// 订单操作(审核通过:approve,审核未通过:reject,取消订单:cancel)
export const operationsApi = (params) => {
  return post(`/repairs/orders/${params.id}/operations/${params.operate}`, { ...params })
}
// 取消代驾订单
export const cancelCarriersApi = (params) => {
  return post(`/repairs/orders/${params.id}/carriers/${params.supplier}/cancel`, { ...params })
}
// 查看代驾下单记录
export const queryCarriersLogs = (params) => {
  return get(`/repairs/orders/${params.id}/carrier_operate_logs`)
}
// 查看代驾信息
export const queryCarriersTrack = (params) => {
  return get(`/repairs/orders/${params.id}/carrier_track`)
}
// 发送短信
export const sendSmsApi = (params) => {
  return get(`/repairs/orders/${params.id}/resend_sms/${params.send_type}`)
}
// 获取驾车距离和e代驾id
export const queryCarriersDistanceApi = (params) => {
  return get(`/repairs/orders/${params.id}/distance?${globalFunction.paramsFormat(params)}`)
}
