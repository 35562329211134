import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'

// import Vuetify from 'vuetify/lib'
import Vuetify from 'vuetify/lib/framework' // 对引入全局 SASS 变量有加速作用 https://vuetifyjs.com/zh-Hans/features/sass-variables/
import zhHans from 'vuetify/es5/locale/zh-Hans'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { zhHans },
    current: 'zhHans',
  },
})
