// 过滤异步路由表，返回符合用户角色权限的路由表
export function filterAsyncRouter(asyncRouterMap, menus) {
  const tempList = []
  // var tempList = []
  asyncRouterMap.forEach((itemA) => {
    // 获取两个参数是否相同
    const r = menus.find((itemB) => {
      return itemA.meta && itemA.meta.title
        ? itemB.title === itemA.meta.title
        : itemB.redirect === itemA.redirect
    })
    if (r) {
      // 如果相同则记录下来
      const obj = Object.assign({}, itemA)
      tempList.push(obj)
      // 递归调用函数
      if (itemA.children && itemA.children.length > 0 && r.children && r.children.length > 0) {
        obj.children = filterAsyncRouter(itemA.children, r.children)
      }
    }
  })
  return tempList
}
// 获取request请求头携带参数,并获取按钮权限
let menuInfo = null
export function getOperationBtns(title) {
  const menu = JSON.parse(localStorage.getItem('addRoutes'))
  menuInfo = getMenusChildrenRecursion(menu[0].children, title)
  sessionStorage.setItem('menu_id', menuInfo.menu)
  return menuInfo.button
}
// 递归获取当前路由对应信息
function getMenusChildrenRecursion(data, title) {
  data.forEach((item) => {
    if (item.title === title) {
      menuInfo = item
    } else {
      if (item.children) {
        getMenusChildrenRecursion(item.children, title)
      }
    }
  })
  return menuInfo
}
