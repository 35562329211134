import btnOperatingStatus from './btnOperatingStatus'
export default function getBtns(model, currentCode) {
  return Object.keys(btnOperatingStatus[model]).filter((item) => {
    const index = btnOperatingStatus[model][item].indexOf(currentCode)
    if (index >= 0) {
      return item
    } else {
      return false
    }
  })
}
