import { get, post, patch } from '@/utils/request' // 获取用户列表
import globalFunction from '@/utils/globalFunction'
// 获取代步车订单详情
export const queryCourtesyOrderDetail = (params) => {
  return get(`/courtesy_cars/orders/${params}`)
}
//  获取代步车待处理列表
export const queryCourtesyWaitPrepareList = (params) => {
  return get(
    `/courtesy_cars/orders/list/pending/${params.step}?${globalFunction.paramsFormat(params)}`,
  )
}
//  获取所有城市
export const queryCities = () => {
  return get('/commons/cities')
}
//  获取代步车步骤条信息
export const queryCourtesyStatusTime = (params) => {
  return get(`/courtesy_cars/orders/${params}/status_time`)
}
// 代步车查看订单用车人手机号
export const queryCourtesyUserAccessInfoApi = (params) => {
  return get(`/courtesy_cars/orders/${params.id}/access_info?access_type=${params.access_type}`)
}
//  代步车订单审核记录
export const queryCourtesyApproveLogs = (params) => {
  return get(`/courtesy_cars/orders/${params}/approve_logs`)
}
// 代步车修改用车信息记录
export const queryCourtesyVehicleUsageInfoLogs = (params) => {
  return get(`/courtesy_cars/orders/${params}/customer/vehicle_usage_info/record`)
}
// 查看出行券关联订单
export const tripRelationsApi = (params) => {
  return post(`/courtesy_cars/orders/${params.id}/relations`, params)
}
// 代步车订单审核
export const courtesyApproveApi = (params) => {
  return post(`/courtesy_cars/orders/${params.id}/approve`, params)
}
//  代步车取消订单
export const courtesyCancelApi = (params) => {
  return post(`/courtesy_cars/orders/${params.id}/cancel`, params)
}
// 获取承租人姓名
export const queryCourtesyRenters = (params) => {
  return get(`/courtesy_cars/orders/${params.id}/renters`)
}
//  取车门店
export const queryCourtesyCollectionPoint = (params) => {
  return get(
    `/courtesy_cars/orders/${params.id}/collection_point?filter=${
      params.filter
    }&collection_booking_time=${params.collection_booking_time}&return_booking_time=${
      params.return_booking_time
    }&collection_address=${encodeURIComponent(params.collection_address)}&collection_latitude=${
      params.collection_latitude
    }&collection_longitude=${params.collection_longitude}&supplier=${
      params.supplier
    }&province_code=${params.province_code}&city_code=${params.city_code}&district_code=${
      params.district_code
    }`,
  )
}
// 一嗨续单渠道获取取车门店
export const queryRenewOrderCollectionPoint = (params) => {
  return get(
    `/courtesy_cars/orders/${params.order_id}/supplier_orders/${params.supplier_orders}/${params.supplier_order_number}`,
  )
}
// 获取车辆信息
export const queryCourtesyCarInfo = (params) => {
  return get(
    `/courtesy_cars/orders/${params.id}/vehicle/detail?collection_booking_time=${params.collection_booking_time}&return_booking_time=${params.return_booking_time}&collection_address=${params.collection_address}&store_id=${params.store_id}`,
  )
}
//  创建订单备车信息
export const courtesyPrepareVehicleApi = (params) => {
  return patch(`/courtesy_cars/orders/${params.id}/prepare_vehicle`, { ...params })
}
// 取消备车
export const courtesyCancelVehicleApi = (params) => {
  return post(
    `/courtesy_cars/orders/${params.id}/prepare_vehicle/${params.supplier_order_number}/cancel`,
    { ...params },
  )
}
// 查看备车记录
export const queryCourtesyPrepareVehicleLogs = (params) => {
  return get(`/courtesy_cars/orders/${params}/prepare_vehicle/record`)
}
//  创建订单用车还车信息
export const courtesyCarrierApi = (params) => {
  return post(
    `/courtesy_cars/orders/${params.id}/carrier/${params.carrier_type}/${params.supplier}`,
    { ...params },
  )
}
// 代步车详情交车信息重发短信接口
export const courtesySendSmsApi = (params) => {
  return get(
    `/courtesy_cars/orders/${params.id}/sms?${Object.keys(params)[1]}=${
      params[Object.keys(params)[1]]
    }`,
  )
}
// 代步车取消代驾订单
export const courtesyCancelCarrierApi = (params) => {
  return post(
    `/courtesy_cars/orders/${params.id}/carrier/${params.carrier_type}/${params.supplier}/cancel`,
    { ...params },
  )
}
// 代步车用户拒收
export const courtesyCustomerRefundedApi = (params) => {
  return post(`/courtesy_cars/orders/${params.id}/refunded`, { ...params })
}
// 补录身份证信息
export const addCarrierInfoApi = (params) => {
  return post(
    `/courtesy_cars/orders/${params.id}/carrier/${params.carrier_type}/${params.supplier}/supplement`,
    { ...params },
  )
}
// 代步车查看代驾信息
export const courtesySeeDrivingInfoApi = (params) => {
  return get(`/courtesy_cars/orders/${params.id}/${params.type}/carrier/track`)
}
// 查看代步车下单记录
export const queryCourtesyCarrierLogs = (params) => {
  return get(`/courtesy_cars/orders/${params.id}/carrier/${params.carrier_type}/logs`)
}
// 代步车查看事故上报
export const queryCourtesyAccidentReports = (params) => {
  return get(`/courtesy_cars/orders/${params}/accident_reports`)
}
// 创建违章信息
export const addViolation = (params) => {
  return post(`/courtesy_cars/orders/${params.id}/violations`, params.data)
}
// 编辑违章信息
export const editViolation = (params) => {
  return patch(`/courtesy_cars/orders/${params.id}/violations/${params.data.id}`, params.data)
}
// 获取违章信息
export const getViolation = (params) => {
  return get(`/courtesy_cars/orders/${params.id}/violations`, params.data)
}
// 代步车关单
export const courtesyCloseOrderApi = (params) => {
  return post(`/courtesy_cars/orders/${params.id}/close`)
}
//  获取专车券详情
export const queryTripOrderDetail = (params) => {
  return get(`/trip_coupons/orders/${params}`)
}
// 获取专车券待处理订单
export const queryTripWaitPrepareList = (params) => {
  return get(
    `/trip_coupons/orders/list/pending/${params.step}?${globalFunction.paramsFormat(params)}`,
  )
}
//  专车券获取代步车步骤条信息
export const queryTripStatusTime = (params) => {
  return get(`/trip_coupons/orders/${params}/status_time`)
}
// 专车券查看订单用车人手机号
export const queryTripUserAccessInfoApi = (params) => {
  return get(`/trip_coupons/orders/${params.id}/access_info?access_type=${params.access_type}`)
}
//  专车券订单审核
export const tripApproveApi = (params) => {
  return post(`/trip_coupons/orders/${params.id}/approve`, params)
}
//  专车券取消订单
export const tripCancelApi = (params) => {
  return post(`/trip_coupons/orders/${params.id}/cancel`, params)
}
//  专车券订单审核记录
export const queryTripApproveLogs = (params) => {
  return get(`/trip_coupons/orders/${params}/approve_logs`)
}
//  获取代步车订单供应商券包配置
export const queryCourtesyCarsTripPackages = (params) => {
  return get(`/courtesy_cars/orders/${params}/packages`)
}
//  获取出行券订单供应商券包配置
export const queryTripCouponsTripPackages = (params) => {
  return get(`/trip_coupons/orders/${params}/packages`)
}
// 专车券发券接口
export const tripPackagesApi = (params) => {
  return post(`/trip_coupons/orders/${params.id}/packages`, params)
}
// 出行券重发短信-发券信息
export const tripSmsApi = (params) => {
  return get(`/trip_coupons/orders/${params}/sms`)
}
// 代步车定损单订单审核
export const courtesyDamageEstimateApproveApi = (params) => {
  return post(`/courtesy_cars/orders/${params.id}/damage_estimate_documents_approve`, params)
}
// 代步车定损单审核记录
export const queryCourtesyDamageEstimateApproveLogs = (params) => {
  return get(`/courtesy_cars/orders/${params}/damage_estimate/approve_logs`)
}
// 出行券定损单订单审核
export const tripDamageEstimateApproveApi = (params) => {
  return post(`/trip_coupons/orders/${params.id}/damage_estimate_documents_approve`, params)
}
// 出行券定损单审核记录
export const queryTripCourtesyDamageEstimateApproveLogs = (params) => {
  return get(`/trip_coupons/orders/${params}/damage_estimate/approve_logs`)
}
// 获取异常费用
export const getAbnormalList = (params) => {
  return get(`/courtesy_cars/orders/${params.id}/abnormal_fees`)
}
// 获取事故车列表
export const getRepairsList = (params) => {
  params.status = params.status || ''
  return get(`/repairs/orders?${globalFunction.paramsFormat(params)}`)
}
// 拨号
export const callDialoutApi = (params) => {
  return get(
    `/courtesy_cars/record/call_dialout?phone=${params.phone}&exten_type=${params.exten_type}&order_id=${params.id}`,
  )
}
// 添加跟进记录
export const createFollowUpRecordApi = (params) => {
  return post(`/courtesy_cars/record`, params)
}
// 查看跟进记录
export const queryRecordApi = (params) => {
  return get(`/courtesy_cars/orders/${params}/record`)
}
// 客户用车信息修改接口
export const editCustomerInfoApi = (params) => {
  return patch(`/courtesy_cars/orders/${params.order_id}/customer/vehicle_usage_info`, params)
}
// 识别证件信息
export const reidentificationApi = (params) => {
  return post(`/courtesy_cars/orders/${params.id}/identity_documents`, params)
}
// 修改证件信息
export const additionalIdentityInfo = (params) => {
  return patch(`/courtesy_cars/orders/${params.id}/identity_documents`, params)
}
// 获取驾车距离和e代驾id
export const queryCarriersDistanceApi = (params) => {
  return get(`/commons/distance?${globalFunction.paramsFormat(params)}`)
}
