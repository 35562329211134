import axios from 'axios'
import router from '../router/index'
import Vue from 'vue'
let baseUrl = ''

if (process.env.NODE_ENV === 'local') {
  // 本地开发环境
  baseUrl = '/v1'
} else if (process.env.NODE_ENV === 'development') {
  // 开发环境
  baseUrl = 'https://dev.admin-api.autobutler.botpy.com/v1/admin_api'
} else if (process.env.NODE_ENV === 'production') {
  // 生产环境
  baseUrl = 'https://admin-api.autobutler.botpy.com/v1'
} else {
  baseUrl = '/v1' // 本地环境
}

const request = axios.create({
  // timeout: 5000,
  baseURL: baseUrl,
  headers: {
    'content-type': 'application/json;charset=utf-8',
  },
})
// 添加请求拦截器
request.interceptors.request.use(
  (config) => {
    if (config.headers && !config.headers.token) {
      const token = localStorage.getItem('token')
      config.headers.Authorization = token
    }
    if (config.headers && !config.headers['Autobutler-Admin-Page-Id']) {
      const menuId = sessionStorage.getItem('menu_id')
      config.headers['Autobutler-Admin-Page-Id'] = menuId
    }
    // 在发送请求之前做些什么
    return config
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error)
  },
)

// 添加响应拦截器
request.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    return response
  },
  (error) => {
    const errorCode = error.response.status
    const reqUrl = error.response.config.url
    if (!reqUrl.includes('/courtesy_cars/record/call_dialout')) {
      if (errorCode === 401) {
        if (reqUrl.includes('/accounts/token_refresh') || reqUrl.includes('/accounts/login')) {
          if (reqUrl.includes('/accounts/login')) {
            alert(error.response.data.errors[0].details[0].message)
          }
          router.replace('/login')
        } else {
          const token = localStorage.getItem('token')
          if (token) {
            //  刷新token
            const refresh = localStorage.getItem('refresh')
            request
              .post('/accounts/token_refresh', { refresh })
              .then((res) => {
                // 存刷新后的token
                localStorage.setItem('token', `AutoButler ${res.data.access}`)
              })
              .catch((error) => {
                localStorage.removeItem('token')
                localStorage.removeItem('refresh')
                localStorage.removeItem('addRoutes')
                alert(error.response.data.errors[0].details[0].message)
                router.replace('/login')
              })
          } else {
            router.replace('/login')
          }
        }
      } else if (errorCode === 403) {
        // 如果权限变更，提示
        if (error.response.data.errors[0].details[0].code === 'permission_denied_or_changed') {
          const vm = new Vue()
          vm.$noAuthorizationMessage({
            content: '权限发生变更，请重新登录',
          })
        } else {
          localStorage.removeItem('token')
          localStorage.removeItem('refresh')
          localStorage.removeItem('addRoutes')
          alert(error.response.data.errors[0].details[0].message)
          router.replace('/login')
        }
      } else if (errorCode === 500) {
        alert('服务器错误，请联系管理员')
      } else {
        if (error.response.data.errors[0].details[0].code === 'account.user.await_handle_order') {
          return Promise.reject(error)
        }
        alert(error.response.data.errors[0].details[0].message)
      }
    }

    // 对响应错误做点什么
    return Promise.reject(error)
  },
)
// // 刷新token
// function getNewToken() {
//   let refresh = localStorage.getItem('refresh')
//   if (!refresh) {
//     // 跳转到登录页
//     router.replace('/login')
//   } else {
//     request.post('/accounts/token_refresh',
//       { refresh }
//     ).then(res => {
//       // 存刷新后的token
//       localStorage.setItem("token", `AutoButler ${res.data.access}`);
//     })
//   }
// }

const get = request.get
const post = request.post
const Delete = request.delete
const put = request.put
const patch = request.patch

export { get, post, Delete, put, patch }
