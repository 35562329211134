import Vue from 'vue'
import Vuex from 'vuex'
import common from './common/index'
import login from './login/index'
import order from './order/index'
import system from './system/index'
import cost from './cost/index'
import log from './log/index'
import config from './config/index'
import repairs from './repairs/index'
import commodity from './commodity/index'
import equities from './equities/index'
import tripCouponStocks from './tripCouponStocks/index'
Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    common,
    login,
    order,
    system,
    cost,
    log,
    config,
    repairs,
    commodity,
    equities,
    tripCouponStocks,
  },
})
export default store
