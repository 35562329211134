export const constantRouterMap = [
  {
    path: '/login',
    hidden: true,
    name: 'login',
    meta: {
      title: '登录',
    },
    component: () => import('@/views/login/index'),
  },
  {
    path: '/404',
    hidden: true,
    name: '404',
    meta: {
      title: '404',
    },
    component: () => import('@/views/404/index.vue'),
  },
]
export const asyncRouterMap = [
  {
    path: '/',
    redirect: '/main',
    hidden: false,
    component: () => import('@/components/Layout.vue'),
    children: [
      {
        path: '/main',
        name: 'main',
        meta: {
          title: '首页',
        },
        icon: 'mdi-home',
        hidden: false,
        component: () => import('@/views/main/index.vue'),
      },
      {
        path: '/order',
        name: 'order',
        redirect: '/order/list',
        meta: {
          title: '订单列表',
        },
        children: [
          {
            path: '/order/list',
            name: 'list',
            meta: {
              title: '列表',
            },
            hidden: false,
            component: () => import('@/views/order/list/index.vue'),
          },
          // 代步车详情
          {
            path: '/order/courtesy_cars_datail',
            name: 'courtesy_cars_datail',
            meta: {
              title: '代步车详情',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/workOrder/courtesy_cars_datail/index.vue'),
          },
          // 专车券详情
          {
            path: '/order/trip_coupons_detail',
            name: 'trip_coupons_detail',
            meta: {
              title: '出行券详情',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/workOrder/trip_coupons_detail/index.vue'),
          },
          {
            path: '/order/repairs_detail',
            name: 'repairs_detail',
            meta: {
              title: '代送修详情',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/workOrder/repairs_detail/index.vue'),
          },
        ],
        icon: 'mdi-cart',
        hidden: false,
        component: () => import('@/views/order/index.vue'),
      },
      {
        path: '/workOrder',
        redirect: '/workOrder/wait_confirm',
        name: 'workOrder',
        meta: {
          title: '工单管理',
        },
        icon: 'mdi-account-switch',
        hidden: false,
        component: () => import('@/views/workOrder/index.vue'),
        children: [
          {
            path: '/workOrder/wait_confirm',
            name: 'wait_confirm',
            meta: {
              title: '待审核',
            },
            hidden: false,
            component: () => import('@/views/workOrder/wait_confirm/index.vue'),
          },
          {
            path: '/workOrder/wait_dispatch_car',
            name: 'wait_dispatch_car',
            meta: {
              title: '待派车',
            },
            hidden: false,
            component: () => import('@/views/workOrder/wait_dispatch_car/index.vue'),
          },
          {
            path: '/workOrder/wait_prepare_car',
            name: 'wait_prepare_car',
            meta: {
              title: '待备车',
            },
            hidden: false,
            component: () => import('@/views/workOrder/wait_prepare_car/index.vue'),
          },
          {
            path: '/workOrder/wait_deliver_car',
            name: 'wait_deliver_car',
            meta: {
              title: '待交车',
            },
            hidden: false,
            component: () => import('@/views/workOrder/wait_deliver_car/index.vue'),
          },
          {
            path: '/workOrder/wait_return_car',
            name: 'wait_return_car',
            meta: {
              title: '待还车',
            },
            hidden: false,
            component: () => import('@/views/workOrder/wait_return_car/index.vue'),
          },
          {
            path: '/workOrder/returned_car',
            name: 'returned_car',
            meta: {
              title: '待关单',
            },
            hidden: false,
            component: () => import('@/views/workOrder/returned_car/index.vue'),
          },
          {
            path: '/workOrder/damage_estimate',
            name: 'damage_estimate',
            meta: {
              title: '定损单',
            },
            hidden: false,
            component: () => import('@/views/workOrder/damage_estimate/index.vue'),
          },
          {
            path: '/workOrder/remind_return_car',
            name: 'remind_return_car',
            meta: {
              title: '还车提醒',
            },
            hidden: false,
            component: () => import('@/views/workOrder/remind_return_car/index.vue'),
          },
          {
            path: '/workOrder/processed_order',
            name: 'processed_order',
            meta: {
              title: '已处理',
            },
            hidden: false,
            component: () => import('@/views/workOrder/processed_order/index.vue'),
          },
          {
            path: '/workOrder/repairs_list',
            name: 'repairs_list',
            meta: {
              title: '代送修列表',
            },
            hidden: false,
            component: () => import('@/views/workOrder/repairs_list/index.vue'),
          },
          {
            path: '/workOrder/repairs_detail',
            name: 'repairs_detail',
            meta: {
              title: '代送修详情',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/workOrder/repairs_detail/index.vue'),
          },
          // 代步车详情
          {
            path: '/workOrder/courtesy_cars_datail',
            name: 'courtesy_cars_datail',
            meta: {
              title: '代步车详情',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/workOrder/courtesy_cars_datail/index.vue'),
          },
          // 专车券详情
          {
            path: '/workOrder/trip_coupons_detail',
            name: 'trip_coupons_detail',
            meta: {
              title: '出行券详情',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/workOrder/trip_coupons_detail/index.vue'),
          },
        ],
      },
      {
        path: '/equities',
        name: 'equities',
        meta: {
          title: '权益管理',
        },
        icon: 'mdi-diamond',
        redirect: '/equities/coupon_list',
        hidden: false,
        component: () => import('@/views/equities/index.vue'),
        children: [
          {
            path: '/equities/coupon_list',
            name: 'equities/coupon_list',
            meta: {
              title: '出行券发放',
            },
            hidden: false,
            component: () => import('@/views/equities/coupon_list/index.vue'),
          },
        ],
      },
      {
        path: '/cost',
        name: 'cost',
        meta: {
          title: '费用管理',
        },
        icon: 'mdi-currency-cny',
        redirect: '/cost/list',
        hidden: false,
        component: () => import('@/views/cost/index.vue'),
        children: [
          {
            path: '/cost/list',
            name: 'cost/list',
            meta: {
              title: '费用列表',
            },
            hidden: false,
            component: () => import('@/views/cost/order_list/index.vue'),
          },
          {
            path: '/cost/cost_detail',
            name: 'cost/cost_detail',
            meta: {
              title: '费用详情',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/cost/cost_detail/index.vue'),
          },
        ],
      },
      {
        path: '/black_admin',
        redirect: '/black_admin/black_list',
        name: 'black_admin',
        meta: {
          title: '黑名单管理',
        },
        icon: 'mdi-account-cancel',
        hidden: false,
        component: () => import('@/views/system/config/index.vue'),
        children: [
          {
            path: '/black_admin/query_list',
            name: 'query_list',
            meta: {
              title: '查询列表',
              guidePath: true,
              jumpPath: '',
            },
            hidden: false,
            component: () => import('@/views/system/config/list/index.vue'),
          },
          {
            path: '/black_admin/black_list',
            name: 'black_list',
            meta: {
              title: '黑名单列表',
              guidePath: true,
              jumpPath: '',
            },
            hidden: false,
            component: () => import('@/views/system/config/list/index.vue'),
          },
          {
            path: '/black_admin/black_list_add',
            name: 'AddBlackList',
            meta: {
              title: '添加黑名单',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/config/blacklist/index.vue'),
          },
          {
            path: '/black_admin/black_list_edit',
            name: 'EditBlackList',
            meta: {
              title: '编辑黑名单',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/config/blacklist/index.vue'),
          },
          {
            path: '/black_admin/black_list_optare',
            name: 'BlackListDetail',
            meta: {
              title: '黑名单详情',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/config/blacklist/index.vue'),
          },
        ],
      },
      {
        path: '/system',
        redirect: '/system/channel',
        name: 'system',
        meta: {
          title: '系统管理',
        },
        icon: 'mdi-cog',
        hidden: false,
        component: () => import('@/views/system/index.vue'),
        children: [
          {
            path: '/system/channel',
            name: 'channel',
            meta: {
              title: '渠道管理',
            },
            hidden: false,
            component: () => import('@/views/system/channel/index.vue'),
          },
          {
            path: '/system/user',
            name: 'user',
            meta: {
              title: '用户管理',
            },
            hidden: false,
            component: () => import('@/views/system/user/index.vue'),
          },
          {
            path: '/system/role',
            name: 'role',
            meta: {
              title: '角色管理',
            },
            hidden: false,
            component: () => import('@/views/system/role/index.vue'),
          },
          {
            path: '/system/add_channel',
            name: 'AddChannel',
            meta: {
              title: '添加渠道',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/add_channel/index.vue'),
          },
          {
            path: '/system/channel_optare',
            name: 'ChannelOptare',
            meta: {
              title: '渠道详情',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/add_channel/index.vue'),
          },
          {
            path: '/system/add_user',
            name: 'AddUser',
            meta: {
              title: '添加用户',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/add_user/index.vue'),
          },
          {
            path: '/system/user_optare',
            name: 'UserOptare',
            meta: {
              title: '用户详情',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/add_user/index.vue'),
          },
          {
            path: '/system/add_role',
            name: 'AddRole',
            meta: {
              title: '角色详情',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/add_role/index.vue'),
          },
        ],
      },
      {
        path: '/system/config',
        redirect: '/system/config/shop',
        name: 'config',
        meta: {
          title: '配置管理',
        },
        hidden: false,
        icon: 'mdi-wrench',
        component: () => import('@/views/system/config/index.vue'),
        children: [
          {
            path: '/system/config/shop',
            name: 'shop',
            meta: {
              title: '门店信息列表',
              guidePath: true,
              jumpPath: '',
            },
            hidden: false,
            component: () => import('@/views/system/config/list/index.vue'),
          },
          {
            path: '/system/config/city',
            name: 'city',
            meta: {
              title: '城市等级列表',
              guidePath: true,
              jumpPath: '',
            },
            hidden: false,
            component: () => import('@/views/system/config/list/index.vue'),
          },
          {
            path: '/system/config/driver',
            name: 'driver',
            meta: {
              title: '代驾信息列表',
              guidePath: true,
              jumpPath: '',
            },
            hidden: false,
            component: () => import('@/views/system/config/list/index.vue'),
          },
          {
            path: '/system/shop_add',
            name: 'AddShop',
            meta: {
              title: '添加门店',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/config/config_detail/index.vue'),
          },
          {
            path: '/system/shop_edit',
            name: 'EditShop',
            meta: {
              title: '编辑门店',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/config/config_detail/index.vue'),
          },
          {
            path: '/system/shop_optare',
            name: 'ShopDetail',
            meta: {
              title: '门店详情',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/config/config_detail/index.vue'),
          },
          {
            path: '/system/city_add',
            name: 'AddCity',
            meta: {
              title: '添加城市等级',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/config/config_detail/index.vue'),
          },
          {
            path: '/system/city_edit',
            name: 'EditCity',
            meta: {
              title: '编辑城市等级',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/config/config_detail/index.vue'),
          },
          {
            path: '/system/city_optare',
            name: 'CityDetail',
            meta: {
              title: '城市等级详情',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/config/config_detail/index.vue'),
          },
          {
            path: '/system/driver_add',
            name: 'AddDriver',
            meta: {
              title: '添加代驾信息',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/config/config_detail/index.vue'),
          },
          {
            path: '/system/driver_edit',
            name: 'EditDriver',
            meta: {
              title: '编辑代驾信息',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/config/config_detail/index.vue'),
          },
          {
            path: '/system/driver_optare',
            name: 'DriverDetail',
            meta: {
              title: '代驾信息详情',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/config/config_detail/index.vue'),
          },
          {
            path: '/system/commodity_list',
            name: 'commodity_list',
            meta: {
              title: '渠道零售列表',
            },
            hidden: false,
            component: () => import('@/views/system/commodity_list/index.vue'),
          },
          {
            path: '/system/commodity_add',
            name: 'commodity_add',
            meta: {
              title: '添加商品',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/commodity_detail/index.vue'),
          },
          {
            path: '/system/commodity_edit',
            name: 'commodity_edit',
            meta: {
              title: '编辑商品',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/commodity_detail/index.vue'),
          },
          {
            path: '/system/commodity_detail',
            name: 'commodity_detail',
            meta: {
              title: '商品详情',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/commodity_detail/index.vue'),
          },
          {
            path: '/system/trip_coupons_stocks/list',
            name: 'TripCouponsStockList',
            meta: {
              title: '出行券库存列表',
            },
            hidden: false,
            component: () => import('@/views/system/trip_coupons_stocks/list/index.vue'),
          },
          {
            path: '/system/trip_coupons_stocks/add',
            name: 'TripCouponsStockAdd',
            meta: {
              title: '添加出行券库存',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/trip_coupons_stocks/detail/index.vue'),
          },
          {
            path: '/system/trip_coupons_stocks/edit',
            name: 'TripCouponsStockEdit',
            meta: {
              title: '编辑出行券库存',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/trip_coupons_stocks/detail/index.vue'),
          },
          {
            path: '/system/trip_coupons_stocks/detail',
            name: 'TripCouponsStockDetail',
            meta: {
              title: '出行券库存详情',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/trip_coupons_stocks/detail/index.vue'),
          },
        ],
      },
      {
        path: '/system/log',
        redirect: '/system/log/loginLog',
        name: 'log',
        meta: {
          title: '日志管理',
        },
        hidden: false,
        icon: 'mdi-sticker-text',
        component: () => import('@/views/system/log/index.vue'),
        children: [
          {
            path: '/system/log/loginLog',
            name: 'loginLog',
            meta: {
              title: '登陆日志',
              guidePath: true,
              jumpPath: '',
            },
            hidden: false,
            component: () => import('@/views/system/log/log_list/index.vue'),
          },
          {
            path: '/system/log/serviceLog',
            name: 'serviceLog',
            meta: {
              title: '代步车日志',
              guidePath: true,
              jumpPath: '',
            },
            hidden: false,
            component: () => import('@/views/system/log/log_list/index.vue'),
          },
          {
            path: '/system/log/couponLog',
            name: 'couponLog',
            meta: {
              title: '出行券日志',
              guidePath: true,
              jumpPath: '',
            },
            hidden: false,
            component: () => import('@/views/system/log/log_list/index.vue'),
          },
          {
            path: '/system/log/log_detail',
            name: 'LogDetail',
            meta: {
              title: '日志详情',
              guidePath: true,
              jumpPath: '',
            },
            hidden: true,
            component: () => import('@/views/system/log/log_detail/index.vue'),
          },
        ],
      },
    ],
  },
]
