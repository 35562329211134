import Vue from 'vue'
import Vuex from 'vuex'
import {
  queryUsersList,
  createUser,
  editorUser,
  delUser,
  queryUsersDetail,
  queryRolesType,
  queryRolesList,
  queryRolesDetail,
  queryCourtesyCallbackDetail,
  queryCourtesySmsDetail,
  queryCarrierApiDetail,
  queryCarrierNotificationDetail,
  queryCourtesyCarsModifyDetail,
  queryRenewalWechatDetail,
  queryTripModifyDetail,
  queryTripCallbackDetail,
  queryTripSmsDetail,
} from '@/api/system/index'
Vue.use(Vuex)
const system = {
  namespaced: true,
  state: {
    // 渠道列表
    // channelsList:[]
    channelSearchVal: {},
    // 用户列表搜索条件
    userSearch: {},
    //  角色列表搜索条件
    rolesSearch: {},
  },
  mutations: {
    setState(state, payload) {
      state[payload.key] = payload.values
    },
  },
  actions: {
    // 获取用户列表
    getUsersList({ commit }, payload) {
      return queryUsersList(payload)
    },
    // 添加用户
    addUser({ commit }, payload) {
      return createUser(payload)
    },
    // 编辑用户
    editUser({ commit }, payload) {
      return editorUser(payload)
    },
    getRolesType({ commit }, payload) {
      return queryRolesType(payload)
    },
    // 删除用户
    deleteUser({ commit }, payload) {
      return delUser(payload)
    },
    // 用户详情
    getUsersDetail({ commit }, payload) {
      return queryUsersDetail(payload)
    },
    // 获取角色列表
    getRolesList({ commit }, payload) {
      return queryRolesList(payload)
    },
    // 角色详情
    getRolesDetail({ commit }, payload) {
      return queryRolesDetail(payload)
    },
    // 代步车日志详情
    // 订单回调日志详情
    getCourtesyCallbackDetail({ commit }, payload) {
      return queryCourtesyCallbackDetail(payload)
    },
    // 短信日志详情
    getCourtesySmsDetail({ commit }, payload) {
      return queryCourtesySmsDetail(payload)
    },
    // 第三方代驾请求日志详情
    getCarrierApiDetail({ commit }, payload) {
      return queryCarrierApiDetail(payload)
    },
    // 第三方代驾回调日志详情
    getCarrierNotificationDetail({ commit }, payload) {
      return queryCarrierNotificationDetail(payload)
    },
    // 微信支付请求回调日志详情
    getRenewalWechatDetail({ commit }, payload) {
      return queryRenewalWechatDetail(payload)
    },
    // 代步车订单修改日志 详情
    getCourtesyCarsModifyDetail({ commit }, payload) {
      return queryCourtesyCarsModifyDetail(payload)
    },
    // 专车券日志详情
    // 专车券订单修改日志 详情
    // getTripModifyDetail
    getTripModifyDetail({ commit }, payload) {
      return queryTripModifyDetail(payload)
    },
    // 订单回调日志详情
    getTripCallbackDetail({ commit }, payload) {
      return queryTripCallbackDetail(payload)
    },
    // 短信日志详情
    getTripSmsDetail({ commit }, payload) {
      return queryTripSmsDetail(payload)
    },
    // 存放渠道页面筛选条件
    changeChannelSearch({ commit }, payload) {
      commit({ type: 'setState', key: 'channelSearchVal', values: payload.values })
    },
  },
}
export default system
