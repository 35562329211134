// 服务类型转化
function judgeBlackList(type) {
  let typeText = null
  if (type === 'vin') {
    typeText = '车架号'
  } else if (type === 'plate_number') {
    typeText = '车牌号'
  } else if (type === 'voucher_code') {
    typeText = '凭证码'
  } else if (type === 'service_number') {
    typeText = '服务编号'
  }
  return typeText
}
// 处理请求接口列表参数
function paramsFormat(params) {
  let str = ''
  for (const key in params) {
    if (params[key] instanceof Array) {
      params[key].forEach((item) => {
        str += `${key}=${item}&`
      })
    } else {
      str += `${key}=${encodeURIComponent(params[key])}&`
    }
  }
  str = str.slice(0, -1)
  return str
}
// 处理异常费用类型数据
function abnormalFeesFormat(type) {
  let type_text = ''
  switch (type) {
    case 'fuel_fee':
      type_text = '燃油费'
      break
    case 'overdue_fee':
      type_text = '超时费'
      break
    case 'damage_fee':
      type_text = '车损费'
      break
    case 'park_fee':
      type_text = '停车费'
      break
    case 'liquidated_damages':
      type_text = '违约金'
      break
    case 'other_fee':
      type_text = '其他'
      break
  }
  return type_text
}
export default { judgeBlackList, paramsFormat, abnormalFeesFormat }
