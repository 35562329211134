import { get, post } from '@/utils/request'
import globalFunction from '@/utils/globalFunction'
// 获取发券面值
export const queryPackageType = (params) => {
  return get(`entitlements/gift_cards/${params.channel_id}/packages`)
}
// 获取车车券列表
export const queryCouponList = (params) => {
  return get(`entitlements/gift_cards?${globalFunction.paramsFormat(params)}`)
}
// 批量发券
export const bulkSeliveryApi = (params) => {
  return post('/entitlements/gift_cards/bulk_delivery', params)
}
// 下载失败原因
export const downloadReasonApi = (params) => {
  return get(`/entitlements/gift_cards/${params}/reason_log`, {
    responseType: 'blob',
  })
}
