let SocketUrl = ''

if (process.env.NODE_ENV === 'local') {
  // 本地开发环境
  SocketUrl = 'wss://dev.admin-api.autobutler.botpy.com:443/v1/admin_api/websocket'
} else if (process.env.NODE_ENV === 'development') {
  // 开发环境
  SocketUrl = 'wss://dev.admin-api.autobutler.botpy.com:443/v1/admin_api/websocket'
} else if (process.env.NODE_ENV === 'production') {
  // 生产环境
  SocketUrl = 'wss://admin-api.autobutler.botpy.com/v1/websocket'
} else {
  SocketUrl = 'wss://dev.admin-api.autobutler.botpy.com:443/v1/admin_api/websocket' // 本地环境
}

const whitePath = [
  '/main',
  '/order/list',
  '/workOrder/processed_order',
  '/workOrder/wait_confirm',
  '/workOrder/damage_estimate',
  '/workOrder/wait_dispatch_car',
  '/workOrder/wait_return_car',
  '/workOrder/returned_car',
  '/workOrder/remind_return_car',
  '/workOrder/repairs_list',
  '/cost/list',
  '/system/channel',
  '/system/user',
  '/system/role',
  '/system/log',
  '/equities/coupon_list',
  '/system/config/shop',
  '/system/config/city',
  '/system/config/driver',
  '/black_admin/query_list',
  '/black_admin/black_list',
  '/system/commodity_list',
  '/system/trip_coupons_stocks/list',
]

export { SocketUrl, whitePath }
