var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialog
    ? _c("div", { staticClass: "mack" }, [
        _c("div", { staticClass: "tipbox" }, [
          _c("div", { staticClass: "font_s" }, [
            _c("img", { staticClass: "icon", attrs: { src: _vm.iconSrc() } }),
            _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.text))]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }