// 组件挂载到vue上
import Message from '../components/noAuthorizationMessage'
import router from '../router'
import vuetify from '../plugins/vuetify'
export default {
  install(Vue) {
    Vue.prototype.$noAuthorizationMessage = function (options) {
      const MessageConstructor = Vue.extend(Message)
      const vue = new Vue({
        router,
        vuetify,
      })
      const el = new MessageConstructor({
        data() {
          return {
            vue,
            ...options,
          }
        },
      }).$mount()
      document.body.appendChild(el.$el)
    }
  },
}
