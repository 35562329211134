<template>
  <v-app>
    <!-- <keep-alive> -->
    <router-view></router-view>
    <!-- </keep-alive>   -->
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    //
  }),
}
</script>
<style>
.tangram-suggestion {
  z-index: 999999;
}
</style>
