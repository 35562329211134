import { get, post, Delete, patch } from '@/utils/request'
// 获取渠道列表
export const getChannels = (params) => {
  return get('/commons/channels', {
    params,
  })
}
// 获取地图围栏城市
export const getFencingCitys = () => {
  return get('/commons/channels/geo_fencing_cities')
}
// 添加渠道
export const addChannels = (params) => {
  return post('/commons/channels', {
    ...params,
  })
}
// 删除渠道
export const deleteChannels = (params) => {
  return Delete(`/commons/channels/${params.id}`)
}
// 获取渠道详情
export const getChannelDetail = (params) => {
  return get(`/commons/channels/${params.id}`)
}
// 编辑修改渠道
export const changeChannel = (params) => {
  return patch(`/commons/channels/${params.id}`, { ...params })
}
//  获取用户列表
export const queryUsersList = (params) => {
  return get('/accounts/users', { params })
}
//  添加用户
export const createUser = (params) => {
  return post('/accounts/users', params)
}
//  修改用户
export const editorUser = (params) => {
  return patch(`/accounts/users/${params.id}`, params.data)
}
//  删除用户
export const delUser = (params) => {
  return Delete(`/accounts/users/${params}`)
}
//  获取用户详情
export const queryUsersDetail = (params) => {
  return get(`/accounts/users/${params}`)
}
//  角色类型
export const queryRolesType = (params) => {
  return get(`/accounts/users/${params}/roles`)
}
//  获取角色列表
export const queryRolesList = (params) => {
  return get('/accounts/roles', { params })
}
// 添加角色
export const createRole = (params) => {
  return post('/accounts/roles', params)
}
//  角色详情
export const queryRolesDetail = (params) => {
  return get(`/accounts/roles/${params}`)
}
//  修改角色
export const editorRole = (params) => {
  return patch(`/accounts/roles/${params.id}`, params)
}
//  删除角色
export const delRole = (params) => {
  return Delete(`/accounts/roles/${params}`)
}
// 获取角色结构数据
export const getRoleStructure = () => {
  return get('accounts/menus')
}
//  代步车日志详情
//  订单回调日志详情
export const queryCourtesyCallbackDetail = (params) => {
  return get(`/courtesy_cars/orders/callback/logs/${params}`)
}
// 短信日志详情
export const queryCourtesySmsDetail = (params) => {
  return get(`/courtesy_cars/orders/sms/logs/${params}`)
}
// 第三方代驾请求日志详情
export const queryCarrierApiDetail = (params) => {
  return get(`/courtesy_cars/orders/carrier_api/logs/${params}`)
}
// 第三方代驾回调日志详情
export const queryCarrierNotificationDetail = (params) => {
  return get(`/courtesy_cars/orders/carrier_notification/logs/${params}`)
}
// 代步车订单修改日志详情
export const queryCourtesyCarsModifyDetail = (params) => {
  return get(`/courtesy_cars/orders/modify/logs/${params}`)
}
// 微信支付请求回调日志详情
export const queryRenewalWechatDetail = (params) => {
  return get(`/courtesy_cars/orders/renewal_wechat/logs/${params}`)
}
//  专车券日志详情
// 专车券订单修改日志详情
export const queryTripModifyDetail = (params) => {
  return get(`/trip_coupons/orders/modify/logs/${params}`)
}
//  订单回调日志详情
export const queryTripCallbackDetail = (params) => {
  return get(`/trip_coupons/orders/callback/logs/${params}`)
}
// 短信日志详情
export const queryTripSmsDetail = (params) => {
  return get(`/trip_coupons/orders/sms/logs/${params}`)
}

// 获取门店地区列表
export const getRegionList = (params) => {
  return get(`/commons/administrative_divisions`, {
    params,
  })
}

// 获取门店信息列表
export const queryShopList = (params) => {
  return get(`/commons/car_points`, {
    params,
  })
}

// 添加门店
export const addShop = (params) => {
  return post(`/commons/car_points`, {
    ...params,
  })
}

// 更新门店
export const updateShop = (params) => {
  return patch(`/commons/car_points/${params.id}`, params)
}

// 门店详情
export const getShopDetail = (id) => {
  return get(`/commons/car_points/${id}`)
}

// 删除门店
export const deleteShop = (id) => {
  return Delete(`/commons/car_points/${id}`)
}

// 获取城市列表
export const queryCityList = (params) => {
  return get(`/commons/city_levels`, {
    params,
  })
}

// 添加城市等级
export const addCityLevel = (params) => {
  return post(`/commons/city_levels`, params)
}

// 更新城市等级
export const updateCityLevel = (params) => {
  return patch(`/commons/city_levels/${params.id}`, params)
}

// 获取城市等级详情
export const getCityLevelDetail = (id) => {
  return get(`/commons/city_levels/${id}`)
}

// 删除城市等级
export const deleteCityLevel = (id) => {
  return Delete(`/commons/city_levels/${id}`)
}

// 获取代驾信息列表
export const queryDriverList = (params) => {
  return get(`/commons/driver_peoples`, {
    params,
  })
}

// 删除代驾信息
export const deleteDriver = (id) => {
  return Delete(`/commons/driver_peoples/${id}`)
}

// 添加代驾信息
export const addDriver = (params) => {
  return post(`/commons/driver_peoples`, params)
}

// 更新代驾信息
export const updateDriver = (params) => {
  return patch(`/commons/driver_peoples/${params.id}`, params)
}

// 获取代驾信息详情
export const getDriverDetail = (id) => {
  return get(`/commons/driver_peoples/${id}`)
}
// 获取黑名单列表
export const queryBlackList = (params) => {
  return get('/commons/blacklists', {
    params,
  })
}
// 获取黑名单详情
export const getBlackListDetail = (id) => {
  return get(`/commons/blacklists/${id}`)
}
// 黑名单查询关联订单
export const queryAssociationOrder = (params) => {
  return get('/commons/blacklists/orders', {
    params,
  })
}
// 黑名单查看操作记录
export const queryCollectionsOperations = ({ id }) => {
  return get(`/commons/blacklists/${id}/logs`, {
    id,
  })
}
// 编辑黑名单
export const updateBlackList = (params) => {
  return patch(`/commons/blacklists/${params.id}`, params)
}
// 添加黑名单
export const addBlackList = (params) => {
  return post('commons/blacklists', params)
}
