import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'

import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

import dayjs from 'dayjs'
import { whitePath } from './utils/globalVariable'
import globalFunction from './utils/globalFunction'
import Message from './utils/message' // 无权限提示
import Toast from './components/Toast/main.js' // 操作提示
import Loading from './components/Loading/main.js' // loading提示
import './styles/cover-vuetify.scss'
import getBtns from '@/utils/getBtns.js'

Sentry.init({
  Vue,
  dsn: 'https://c80aaa857a724f9c98a44bd6760fd24f@sentry.botpy.com/25',
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  tracingOptions: {
    trackComponents: true,
  },
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})

Vue.config.productionTip = false

dayjs.locale('zh-cn')
Vue.prototype.dayjs = dayjs
Vue.prototype.globalFunction = globalFunction
Vue.prototype.$getBtns = getBtns
Vue.prototype.whitePath = whitePath
Vue.use(Message)
Vue.use(Toast)
Vue.use(Loading)
new Vue({
  router,
  store,
  vuetify,
  dayjs,
  render: (h) => h(App),
}).$mount('#app')
