<template>
  <div v-if="dialog" class="mack">
    <div class="tipbox">
      <div class="font_s">
        <img :src="iconSrc()" class="icon" />
        <span class="title">{{ text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToastCom',
  data() {
    return {
      dialog: true,
      text: '操作成功',
      type: 'success',
      duration: 2000,
    }
  },
  mounted() {
    setTimeout(() => {
      this.dialog = false
    }, this.duration)
  },
  methods: {
    iconSrc() {
      let src = ''
      switch (this.type) {
        case 'success':
          src = require('../../assets/img/toast/dial_state_success@2x.png')
          break
        case 'error':
          src = require('../../assets/img/toast/dial_state_fail@2x.png')
          break
      }
      return src
    },
  },
}
</script>

<style lang="scss" scoped>
.mack {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(21, 21, 21, 0.7);
}
.tipbox {
  background: #ffffff;
  border-radius: 2px;
  width: 400px;
  min-width: 400px;
  padding: 0 20px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.font_s {
  height: 100px;
  color: #333333;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon {
  width: 50px;
  height: 50px;
  // border-radius: 50%;
}
.title {
  font-size: 16px;
  color: #333333;
  font-weight: 500;
}
</style>
