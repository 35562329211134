import { get, post, patch, Delete } from '@/utils/request'

import globalFunction from '@/utils/globalFunction'
// 商品列表
export const queryRetailsOrders = (params) => {
  return get(`/commons/retails?${globalFunction.paramsFormat(params)}`)
}
// 添加商品
export const creatRetails = (params) => {
  return post('/commons/retails', params)
}
// 查询商品
export const queryRetails = (params) => {
  return get(`/commons/retails/${params}`)
}
// 修改商品
export const editRetails = (params) => {
  return patch(`/commons/retails/${params.id}`, params.data)
}
// 删除商品
export const deleteRetailsOrder = (params) => {
  return Delete(`/commons/retails/${params.id}`)
}
