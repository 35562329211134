import Vue from 'vue'
import Vuex from 'vuex'
import {
  queryCourtesyWaitPrepareList,
  queryTripWaitPrepareList,
  queryCourtesyApproveLogs,
  queryCourtesyVehicleUsageInfoLogs,
  tripRelationsApi,
  queryCourtesyUserAccessInfoApi,
  courtesyApproveApi,
  courtesyCancelApi,
  queryCourtesyRenters,
  queryCourtesyCollectionPoint,
  courtesyPrepareVehicleApi,
  courtesyCarrierApi,
  courtesySendSmsApi,
  courtesyCancelCarrierApi,
  courtesyCustomerRefundedApi,
  courtesySeeDrivingInfoApi,
  queryCourtesyCarrierLogs,
  queryCourtesyAccidentReports,
  queryCourtesyOrderDetail,
  addViolation,
  editViolation,
  getViolation,
  queryTripOrderDetail,
  queryTripUserAccessInfoApi,
  queryTripApproveLogs,
  tripApproveApi,
  tripCancelApi,
  queryCourtesyCarsTripPackages,
  queryTripCouponsTripPackages,
  tripPackagesApi,
  tripSmsApi,
  queryCourtesyStatusTime,
  queryTripStatusTime,
  courtesyDamageEstimateApproveApi,
  queryCourtesyDamageEstimateApproveLogs,
  tripDamageEstimateApproveApi,
  queryTripCourtesyDamageEstimateApproveLogs,
  getAbnormalList,
  queryCourtesyCarInfo,
  courtesyCancelVehicleApi,
  addCarrierInfoApi,
  queryCourtesyPrepareVehicleLogs,
  callDialoutApi,
  queryRecordApi,
  createFollowUpRecordApi,
  editCustomerInfoApi,
  reidentificationApi,
  queryCarriersDistanceApi,
} from '@/api/order'
Vue.use(Vuex)
const order = {
  namespaced: true,
  state: {
    tabIndex: 0, // 总订单列表tab
    searchVal: {}, // 总订单列表搜索条件
    // confirmStatus: '', // 待审核状态
    waitConfirmIndex: 0, // 待审核tab
    waitConfirmSearchVal: {}, // 待审核条件
    waitPrepareCarIndex: 0, // 待派车列表 代步车订单 0 专车券订单 1
    waitPrepareCarFastSearch: '', // 待派车列表 快速搜索条件
    waitPrepareCarSearch: {}, // 待派车列表 搜索条件
    waitReturnStatus: '', // 待还车状态
    waitReturnSearchVal: {}, // 待还车条件
    returnedStatus: '', // 待关单状态
    returnedSearchVal: {}, // 待关单条件
    damageIndex: 0, // 定损单tab
    damageSearchVal: {}, // 定损单条件
    remindReturnStatus: '', // 还车提醒状态
    remindReturnSearchVal: {}, // 还车提醒搜索条件
    processedSearchVal: {}, // 已处理搜索条件
    repairsStatus: '', // 代送修筛选状态
    repairsSearchVal: {}, // 代送修搜索条件
    storeBeforeIndex: '',

    // 待交车列表 搜索条件
    waitDeliverCarSearch: {},
    // 待交车列表 快速搜索条件
    waitDeliverCarFastSearch: '',
  },
  mutations: {
    setState(state, payload) {
      state[payload.key] = payload.values
    },
  },
  actions: {
    // 获取代步车订单详情
    getCourtesyOrderDetail({ commit, state }, payload) {
      return queryCourtesyOrderDetail(payload)
    },
    // 获取待处理列表
    getWaitPrepareList({ commit, state, dispatch }, payload) {
      payload.status = payload.status || ''
      if (state.waitPrepareCarIndex === 0) {
        // 代步车待处理订单
        return dispatch('getCourtesyWaitPrepareList', payload)
      } else {
        // 获取专车券待处理订单
        return dispatch('getTripWaitPrepareList', payload)
      }
    },
    // 代步车待处理订单
    getCourtesyWaitPrepareList({ commit, state }, payload) {
      return queryCourtesyWaitPrepareList(payload)
    },
    // 专车券待处理订单
    getTripWaitPrepareList({ commit, state }, payload) {
      return queryTripWaitPrepareList(payload)
    },
    // 获取代步车步骤条信息
    getCourtesyStatusTime({ commit, state }, payload) {
      return queryCourtesyStatusTime(payload)
    },
    // 代步车查看订单用车人手机号
    getCourtesyUserAccessInfo({ commit, state }, payload) {
      return queryCourtesyUserAccessInfoApi(payload)
    },
    // 获取代步车审核记录
    getCourtesyApproveLogs({ commit, state }, payload) {
      return queryCourtesyApproveLogs(payload)
    },
    // 代步车修改用车信息记录
    getCourtesyVehicleUsageInfoLogs({ commit, state }, payload) {
      return queryCourtesyVehicleUsageInfoLogs(payload)
    },
    // 查看出行券关联订单
    tripRelations({ commit, state }, payload) {
      return tripRelationsApi(payload)
    },
    // 代步车订单审核
    courtesyApprove({ commit, state }, payload) {
      return courtesyApproveApi(payload)
    },
    // 代步车取消订单
    courtesyCancel({ commit, state }, payload) {
      return courtesyCancelApi(payload)
    },
    // 获取承租人姓名
    getCourtesyRenters({ commit, state }, payload) {
      return queryCourtesyRenters(payload)
    },
    // 获取取车门店
    getCourtesyCollectionPoint({ commit, state }, payload) {
      return queryCourtesyCollectionPoint(payload)
    },
    // 获取车辆信息
    getCourtesyCarInfo({ commit, state }, payload) {
      return queryCourtesyCarInfo(payload)
    },
    // 创建订单备车信息
    courtesyPrepareVehicle({ commit, state }, payload) {
      return courtesyPrepareVehicleApi(payload)
    },
    // 取消备车
    courtesyCancelVehicle({ commit, state }, payload) {
      return courtesyCancelVehicleApi(payload)
    },
    // 查看备车记录
    getCourtesyPrepareVehicleLogs({ commit, state }, payload) {
      return queryCourtesyPrepareVehicleLogs(payload)
    },
    // 创建订单用车还车信息
    courtesyCarrier({ commit, state }, payload) {
      return courtesyCarrierApi(payload)
    },
    // 创建补录身份信息
    addCarrierInfo({ commit, state }, payload) {
      return addCarrierInfoApi(payload)
    },
    // 代步车详情交车信息重发短信接口
    courtesySendSms({ commit, state }, payload) {
      return courtesySendSmsApi(payload)
    },
    // 取消代驾订单
    courtesyCancelCarrier({ commit, state }, payload) {
      return courtesyCancelCarrierApi(payload)
    },
    // 用户拒收
    courtesyCustomerRefunded({ commit, state }, payload) {
      return courtesyCustomerRefundedApi(payload)
    },
    // 代步车查看代驾信息
    courtesySeeDrivingInfo({ commit, state }, payload) {
      return courtesySeeDrivingInfoApi(payload)
    },
    // 代步车查看下单记录
    getCourtesyCarrierLogs({ commit, state }, payload) {
      return queryCourtesyCarrierLogs(payload)
    },
    //  代步车查看事故上报
    getCourtesyAccidentReports({ commit, state }, payload) {
      return queryCourtesyAccidentReports(payload)
    },
    // 创建 违章信息
    creatViolation({ commit, state }, payload) {
      return addViolation(payload)
    },
    // 修改 违章信息
    changeViolation({ commit, state }, payload) {
      return editViolation(payload)
    },
    // 获取违章信息
    getListViolation({ commit, state }, payload) {
      return getViolation(payload)
    },
    // 获取异常费用
    getListAbnormal({ commit, state }, payload) {
      return getAbnormalList(payload)
    },
    // 代步车定损单审核
    courtesyDamageEstimateApprove({ commit, state }, payload) {
      return courtesyDamageEstimateApproveApi(payload)
    },
    // 代步车定损单审核记录
    getCourtesyDamageEstimateApproveLogs({ commit, state }, payload) {
      return queryCourtesyDamageEstimateApproveLogs(payload)
    },
    // 获取专车券详情 queryTripOrderDetail
    getTripOrderDetail({ commit, state }, payload) {
      return queryTripOrderDetail(payload)
    },
    // 获取代步车步骤条信息
    getTripStatusTime({ commit, state }, payload) {
      return queryTripStatusTime(payload)
    },
    // 专车券查看订单用车人手机号
    getTripUserAccessInfo({ commit, state }, payload) {
      return queryTripUserAccessInfoApi(payload)
    },
    // 专车券取消订单
    tripCancel({ commit, state }, payload) {
      return tripCancelApi(payload)
    },
    // 专车券订单审核
    tripApprove({ commit, state }, payload) {
      return tripApproveApi(payload)
    },
    // 获取专车券审核记录
    getTripApproveLogs({ commit, state }, payload) {
      return queryTripApproveLogs(payload)
    },
    // 获取代步车订单供应商券包配置
    getCourtesyCarsTripPackages({ commit, state }, payload) {
      return queryCourtesyCarsTripPackages(payload)
    },
    // 获取出行券订单供应商券包配置
    getTripCouponsTripPackages({ commit, state }, payload) {
      return queryTripCouponsTripPackages(payload)
    },
    // 专车券发券接口
    tripPackages({ commit, state }, payload) {
      return tripPackagesApi(payload)
    },
    // 出行券定损单审核
    tripDamageEstimateApprove({ commit, state }, payload) {
      return tripDamageEstimateApproveApi(payload)
    },
    // 出行券定损单审核记录
    getTripCourtesyDamageEstimateApproveLogs({ commit, state }, payload) {
      return queryTripCourtesyDamageEstimateApproveLogs(payload)
    },
    // 重发短信-发券信息
    tripSms({ commit, state }, payload) {
      return tripSmsApi(payload)
    },
    // 拨号
    callDialout({ commit, state }, payload) {
      return callDialoutApi(payload)
    },
    // 添加客服跟进记录
    createFollowUpRecord({ commit, state }, payload) {
      return createFollowUpRecordApi(payload)
    },
    // 查看跟进记录 queryRecordApi
    getRecord({ commit, state }, payload) {
      return queryRecordApi(payload)
    },
    // 客户用车信息修改接口
    editCustomerInfo({ commit, state }, payload) {
      return editCustomerInfoApi(payload)
    },
    // 识别证件信息
    reidentification({ commit, state }, payload) {
      return reidentificationApi(payload)
    },
    // 获取驾车距离和e代驾id
    getCarriersDistance({ commit, state }, payload) {
      return queryCarriersDistanceApi(payload)
    },
    // changeConfirmStatus({ commit }, payload) {
    //   commit({ type: 'setState', key: 'confirmStatus', values: payload.values })
    // },
  },
}

export default order
