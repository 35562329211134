// 组件挂载到vue上
import Vue from 'vue'
import Loading from '@/components/Loading/main.vue'

const CreateLoading = Vue.extend(Loading)

const instance = new CreateLoading({
  el: document.createElement('div'),
})
const $loading = {
  show(text) {
    document.body.appendChild(instance.$el)
    instance.show = true
    instance.text = text || '正在提交，请稍后...'
  },
  hide() {
    instance.show = false
  },
}
export default {
  install() {
    if (!Vue.$loading) {
      Vue.$loading = $loading
    }
    Vue.mixin({
      created() {
        this.$loading = Vue.$loading
      },
    })
  },
}
