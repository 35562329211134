import Vue from 'vue'
import Vuex from 'vuex'
import { queryCities } from '@/api/order'
import { queryAssociatedDetail } from '@/api/repairs'
import { getChannels, queryUsersList } from '@/api/system'
import { queryPackageType } from '@/api/equities'
Vue.use(Vuex)
const common = {
  namespaced: true,
  state: {
    // 代步车所有可选订单状态
    orderStatus: [
      { name: '待审核', value: 'awaiting_approve' },
      { name: '审核通过', value: 'approved' },
      { name: '审核未通过', value: 'rejected' },
      { name: '待备车', value: 'awaiting_prepare' },
      { name: '备车成功', value: 'prepare_succeed' },
      { name: '待下代驾订单', value: 'carrier_order_planning' },
      { name: '已创建的代驾订单', value: 'carrier_order_created' },
      { name: '交车代驾取消', value: 'carrier_cancelled' },
      { name: '送车中', value: 'carrier_deliverying' },
      { name: '已交付', value: 'delivered' },
      { name: '用车中', value: 'in_use' },
      { name: '待还车', value: 'awaiting_return' },
      { name: '已创建的还车订单', value: 'return_carrier_order_created' },
      { name: '取车中', value: 'going_to_collection_point' },
      { name: '已还车', value: 'returned' },
      { name: '还车代驾取消', value: 'return_carrier_cancelled' },
      { name: '超时未还车', value: 'return_overtime' },
      { name: '延时还车', value: 'lease_renewal' },
      { name: '订单取消', value: 'cancelled' },
      { name: '待关单', value: 'awaiting_close' },
      { name: '已关单', value: 'colsed' },
      { name: '定损单待上传', value: 'damage_estimate_awaiting_upload' },
      { name: '定损单待审核', value: 'damage_estimate_uploaded' },
      { name: '定损单审核通过', value: 'damage_estimate_approved' },
      { name: '定损单审核未通过', value: 'damage_estimate_rejected' },
      { name: '定损单逾期未上传', value: 'damage_estimate_upload_overdue' },
    ],
    // 出行券所有可选订单状态
    tripOrderStatus: [
      { name: '待审核', value: 'awaiting_approve' },
      { name: '审核通过', value: 'approved' },
      { name: '审核未通过', value: 'rejected' },
      { name: '待发券', value: 'awaiting_delivery' },
      { name: '已发券', value: 'delivered' },
      { name: '订单取消', value: 'cancelled' },
      { name: '定损单待上传', value: 'damage_estimate_awaiting_upload' },
      { name: '定损单待审核', value: 'damage_estimate_uploaded' },
      { name: '定损单审核通过', value: 'damage_estimate_approved' },
      { name: '定损单审核未通过', value: 'damage_estimate_rejected' },
      { name: '定损单逾期未上传', value: 'damage_estimate_upload_overdue' },
    ],
    repairOrderStatus: [
      { name: '待审核', value: 'awaiting_approve' },
      { name: '审核未通过', value: 'rejected' },
      { name: '待下代驾', value: 'carrier_order_planning' },
      { name: '已创建代驾', value: 'carrier_order_created' },
      { name: '取消代驾', value: 'carrier_cancelled' },
      { name: '代驾取车中', value: 'going_to_collection_point' },
      { name: '送车中', value: 'carrier_deliverying' },
      { name: '已送达', value: 'delivered' },
      { name: '已完成', value: 'finished' },
      { name: '订单已取消', value: 'cancelled' },
      { name: '异常订单', value: 'exception' },
    ],
    // 所有城市
    allCities: [],
    // 所有渠道
    allChannels: [],
    // 操作人
    allUsersList: [],
    // 发放面值
    packageType: [],
    // 订单类型
    orderTypes: [
      { name: '代步车', value: 'courtesy_cars' },
      { name: '出行券', value: 'trip_coupons' },
    ],
    repairOrderTypes: [
      { name: '送车', value: 'send' },
      { name: '还车', value: 'bring_back' },
    ],
    BlackListOrderTypes: [
      { name: '代步车', value: 'courtesy_car' },
      { name: '出行券', value: 'trip_coupon' },
      { name: '代送修', value: 'repair' },
    ],
    // 供应商类型
    supplierType: [
      { name: 'e代驾', value: 'edaijia' },
      { name: '滴滴', value: 'didi' },
      { name: '客户自取', value: 'customer_collected' },
      { name: '加盟商送车', value: 'franchisee' },
    ],
    // 还车供应商类型
    returnSupplierType: [
      { name: 'e代驾', value: 'edaijia' },
      { name: '滴滴', value: 'didi' },
      { name: '客户自还', value: 'customer_delivered' },
      { name: '加盟商取车', value: 'franchisee' },
    ],
    // 事故车供应商类型
    repairsSupplierType: [{ name: 'e代驾', value: 'edaijia' }],
    // 识别证件类型
    IDType: [
      { name: '身份证人像面', value: 'identity_card_obverse_image' },
      { name: '身份证国徽面', value: 'identity_card_reverse_image' },
      { name: '驾驶证正页', value: 'driving_licence_reserved_copy_image' },
      { name: '驾驶证副页', value: 'driving_licence_additional_paper_image' },
    ],
    // 审核未通过原因
    reject_reason: [
      { name: '欺诈', value: 'general.fraud' },
      { name: '违反服务协议', value: 'general.terms_of_service' },
      { name: '在第三方禁止的名单中', value: 'general.listed' },
      { name: '在黑名单中', value: 'general.blacklisted' },
      { name: '申请受限', value: 'general.restricted_application' },
      { name: '错误的电话号码', value: 'general.phone_invalid' },
      { name: '该电话无法联系到用户', value: 'general.phone_not_reachable' },
      { name: '未在服务期内', value: 'general.customer_out_of_service' },
      { name: '该客户已失效', value: 'general.customer_expired' },
      { name: '该客户还未生效', value: 'general.customer_inactive' },
      { name: '保单已失效', value: 'general.insurance_policy_expired' },
      { name: '保单还未生效', value: 'general.insurance_policy_inactive' },
      { name: '有违章尚未处理', value: 'general.has_pending_violations' },
      { name: '报案号错误', value: 'general.claim_notif_number_invalid' },
      { name: '该地区尚未开通服务', value: 'general.no_service_in_the_area' },
      { name: '无责方无法使用该服务', value: 'general.no_service_to_non_responsible_party' },
      { name: '有逾期还车记录', value: 'general.has_record_of_overdue_return' },
      { name: '其他原因', value: 'general.other' },
      { name: '用车位置错误 / 不精确', value: 'delivery.address_invalid' },
      { name: '用车时间错误', value: 'delivery.time_invalid' },
      { name: '用车时间未在服务时间内', value: 'delivery.booking_time_not_in_service_time' },
      { name: '有未结款项 / 费用', value: 'fee.has_outstanding' },
      { name: '证件非原件拍摄照', value: 'identity_document.non_original_photo_shoot' },
      { name: '身份证损坏', value: 'identity_document.identity_card_corrupt' },
      { name: '身份证信息不完整', value: 'identity_document.identity_card_incomplete' },
      { name: '身份证无法识别', value: 'identity_document.identity_card_not_readable' },
      { name: '身份证缺失正面', value: 'identity_document.identity_card_missing_obverse' },
      { name: '身份证缺失背面', value: 'identity_document.identity_card_missing_reverse' },
      { name: '身份证姓名不匹配', value: 'identity_document.identity_card_name_mismatch' },
      { name: '身份证过期', value: 'identity_document.identity_card_expired' },
      { name: '身份证其他错误', value: 'identity_document.identity_card_invalid_other' },
      { name: '驾驶证损坏', value: 'identity_document.driving_licence_corrupt' },
      { name: '驾驶证信息不完整', value: 'identity_document.driving_licence_incomplete' },
      { name: '驾驶证无法识别', value: 'identity_document.driving_licence_not_readable' },
      { name: '驾驶证缺失正页', value: 'identity_document.driving_licence_missing_reserved_copy' },
      {
        name: '驾驶证缺失副页',
        value: 'identity_document.driving_licence_missing_additional_paper',
      },
      { name: '驾驶证类型不支持', value: 'identity_document.driving_licence_type_not_supported' },
      { name: '驾驶证姓名不匹配', value: 'identity_document.driving_licence_name_mismatch' },
      { name: '驾驶证过期', value: 'identity_document.driving_licence_expired' },
      { name: '驾驶证其他错误', value: 'identity_document.driving_licence_invalid_other' },
      { name: '定损单无法识别', value: 'damage_estimate_document.not_readable' },
      { name: '定损单姓名不匹配', value: 'damage_estimate_document.name_mismatch' },
      { name: '定损单车牌号不匹配', value: 'damage_estimate_document.plate_number_mismatch' },
      { name: '定损单VIN码不匹配', value: 'damage_estimate_document.vin_mismatch' },
      { name: '定损单缺少定损日期', value: 'damage_estimate_document.missing_estimated_date' },
      { name: '定损单缺少出险日期', value: 'damage_estimate_document.missing_claimed_date' },
      {
        name: '定损单缺少修理项目/更换配件信息',
        value: 'damage_estimate_document.missing_repair_info',
      },
      {
        name: '定损单缺少保险公司签章',
        value: 'damage_estimate_document.missing_insurance_company_signature',
      },
      { name: '定损单非原件上传', value: 'damage_estimate_document.non_original_upload' },
      { name: '定损单不完整', value: 'damage_estimate_document.incomplete' },
      { name: '定损单其他错误', value: 'damage_estimate_document.invalid_other' },
    ],
    // 取消订单原因
    cancel_reason: [
      { name: '该地区尚未开通服务', value: 'general.no_service_in_the_area' },
      { name: '送车时无法联系到用户', value: 'general.phone_not_reachable' },
      { name: '非本人申请', value: 'general.application_by_others' },
      { name: '车辆未能按时送达', value: 'delivery.overdue' },
      { name: '车辆库存不足', value: 'vehicle.no_enough_stock' },
      { name: '无法安排承运人', value: 'carrier.assign_failed' },
      { name: '用车计划有变', value: 'customer.plan_has_changed' },
      { name: '已选择其他代步方式', value: 'customer.choose_other_alternative' },
      { name: '暂时不需要代步车', value: 'customer.no_service_required' },
      { name: '车辆不符合要求', value: 'customer.vehicle_not_meet_requirements' },
      { name: '其他原因', value: 'general.other' },
    ],
    // 事故车取消订单原因
    repairs_cancel_reason: [
      { name: '该地区尚未开通服务', value: 'general.no_service_in_the_area' },
      { name: '送车时无法联系到用户', value: 'general.phone_not_reachable' },
      { name: '车辆未能按时送达', value: 'general.application_by_others' },
      { name: '无法安排承运人', value: 'carrier.assign_failed' },
      { name: '用车计划有变', value: 'customer.plan_has_changed' },
      { name: '其他原因', value: 'general.other' },
    ],
    // 事故车审核未通过原因
    repairs_reject_reason: [
      { name: '欺诈', value: 'general.fraud' },
      { name: '违反服务协议', value: 'general.terms_of_service' },
      { name: '在第三方禁止的名单中', value: 'general.listed' },
      { name: '在黑名单中', value: 'general.blacklisted' },
      { name: '申请受限', value: 'general.restricted_application' },
      { name: '错误的电话号码', value: 'general.phone_invalid' },
      { name: '该电话无法联系到用户', value: 'general.phone_not_reachable' },
      { name: '未在服务期内', value: 'general.customer_out_of_service' },
      { name: '该客户已失效', value: 'general.customer_expired' },
      { name: '该客户还未生效', value: 'general.customer_inactive' },
      { name: '保单已失效', value: 'general.insurance_policy_expired' },
      { name: '保单还未生效', value: 'general.insurance_policy_inactive' },
      { name: '有违章尚未处理', value: 'general.has_pending_violations' },
      { name: '报案号错误', value: 'general.claim_notif_number_invalid' },
      { name: '该地区尚未开通服务', value: 'general.no_service_in_the_area' },
      { name: '无责方无法使用该服务', value: 'general.no_service_to_non_responsible_party' },
      { name: '有逾期还车记录', value: 'general.has_record_of_overdue_return' },
      { name: '错误的车牌号', value: 'general.plate_number_invalid' },
      { name: '起点位置错误/不精确', value: 'from.address_invalid' },
      { name: '无法联系到起点联系人', value: 'from.contact_phone_not_reachable' },
      { name: '预约取车时间未在服务时间内', value: 'from.booking_time_not_in_service_time' },
      { name: '终点位置错误/不精确', value: 'to.address_invalid' },
      { name: '无法联系到终点联系人', value: 'to.contact_phone_not_reachable' },
      { name: '预约送车时间未在服务时间内', value: 'to.booking_time_not_in_service_time' },
      { name: '有未结款项 / 费用', value: 'fee.has_outstanding' },
      { name: '定损单无法识别', value: 'damage_estimate_document.not_readable' },
      { name: '定损单姓名不匹配', value: 'damage_estimate_document.name_mismatch' },
      { name: '定损单车牌号不匹配', value: 'damage_estimate_document.plate_number_mismatch' },
      { name: '定损单VIN码不匹配', value: 'damage_estimate_document.vin_mismatch' },
      { name: '定损单缺少定损日期', value: 'damage_estimate_document.missing_estimated_date' },
      { name: '定损单缺少出险日期', value: 'damage_estimate_document.missing_claimed_date' },
      {
        name: '定损单缺少修理项目/更换配件信息',
        value: 'damage_estimate_document.missing_repair_info',
      },
      {
        name: '定损单缺少保险公司签章',
        value: 'damage_estimate_document.missing_insurance_company_signature',
      },
      { name: '定损单非原件上传', value: 'damage_estimate_document.non_original_upload' },
      { name: '定损单不完整', value: 'damage_estimate_document.incomplete' },
      { name: '定损单其他错误', value: 'damage_estimate_document.invalid_other' },
      { name: '其他原因', value: 'general.other' },
    ],
    // 定损单审核未通过原因
    damage_estimate_reject_reason: [
      { name: '定损单无法识别', value: 'damage_estimate_document.not_readable' },
      { name: '定损单姓名不匹配', value: 'damage_estimate_document.name_mismatch' },
      { name: '定损单车牌号不匹配', value: 'damage_estimate_document.plate_number_mismatch' },
      { name: '定损单VIN码不匹配', value: 'damage_estimate_document.vin_mismatch' },
      { name: '定损单缺少定损日期', value: 'damage_estimate_document.missing_estimated_date' },
      { name: '定损单缺少出险日期', value: 'damage_estimate_document.missing_claimed_date' },
      {
        name: '定损单缺少修理项目/更换配件信息',
        value: 'damage_estimate_document.missing_repair_info',
      },
      {
        name: '定损单缺少保险公司签章',
        value: 'damage_estimate_document.missing_insurance_company_signature',
      },
      { name: '定损单非原件上传', value: 'damage_estimate_document.non_original_upload' },
      { name: '定损单不完整', value: 'damage_estimate_document.incomplete' },
      { name: '定损单其他错误', value: 'damage_estimate_document.invalid_other' },
    ],
    // 账号状态
    is_staff: [
      { name: '启用', value: 'true' },
      { name: '禁用', value: 'false' },
    ],
    // 接单状态
    is_accept: [
      { name: '开启', value: 'true' },
      { name: '关闭', value: 'false' },
    ],
    // 城市等级
    city_level: [
      { name: 'A级', value: 'A' },
      { name: 'B级', value: 'B' },
      { name: 'C级', value: 'C' },
    ],
    supplier: [
      { name: '神州', value: 'shenzhou' },
      { name: '一嗨', value: '1hai' },
      { name: '一嗨（续单）', value: '1hai_renewal' },
      { name: '长租车', value: 'long_lease' },
      { name: '加盟商', value: 'franchisee' },
    ],
    violationStatus: [
      { name: '未处理', value: 'unhandle' },
      { name: '客户自行处理', value: 'customer_handle' },
      { name: '代驾自行处理', value: 'carrier_handle' },
      { name: '客户缴费处理', value: 'hand_in_fee' },
      { name: '神州减免', value: 'shenzhou_discount' },
    ],
    driverSupplier: [{ name: 'e代驾', value: 'edaijia' }],
    // 跟进状态
    followUpStatus: [
      { name: '已登记', value: 'Registered' },
      { name: '已联系', value: 'Contacted' },
      { name: '无人接听/拒接', value: 'NoAnswer' },
      { name: '调度跟进', value: 'Scheduling' },
      { name: '续单', value: 'RenewalOrder' },
      { name: '续租', value: 'RenewalLease' },
    ],
    useMode: [
      { name: '管家送车', value: '0' },
      { name: '门店自提', value: '1' },
    ],
    cooperationModeList: [
      { name: '使用博派用户端', value: 'h5' },
      { name: '接口对接', value: 'api' },
    ],
    // 商品类别
    shopType: [
      { name: '代步车', value: 'courtesy_car' },
      { name: '租车卡', value: 'car_rental_card' },
    ],
    // 出险代步车享权类型
    courtesyCarVoucherType: [
      { name: '车架号', value: 'vin' },
      { name: '服务编号', value: 'gift' },
      { name: '车牌号', value: 'plate_number' },
      { name: '凭证码', value: 'ticket' },
    ],
    // 租车卡享权类型
    carRentalCardVoucherType: [{ name: '凭证码', value: 'ticket' }],
    whether: [
      { name: '是', value: 'true' },
      { name: '否', value: 'false' },
    ],
    // 发放状态
    is_send: [
      { name: '已发放', value: '1' },
      { name: '发放中', value: '0' },
    ],
    // 结算状态
    settlementStatusList: [
      { name: '已结算', value: '1' },
      { name: '未结算', value: '0' },
    ],
    // 发放形式
    send_mode: [
      { name: '直充', value: 'recharge' },
      { name: '库存', value: 'stock' },
    ],
    // 异常费用支付状态
    abnormalFeePaidList: [
      { name: '待支付', value: '0' },
      { name: '已支付', value: '1' },
    ],
  },
  mutations: {
    setState(state, payload) {
      state[payload.key] = payload.values
    },
  },
  actions: {
    // 获取所有城市
    getCities({ commit, state }) {
      queryCities().then((res) => {
        commit({ type: 'setState', key: 'allCities', values: res.data.data })
      })
    },
    // 获取所有渠道
    getChannels({ commit, state }, payload) {
      getChannels(payload).then((res) => {
        commit({ type: 'setState', key: 'allChannels', values: res.data.data })
      })
    },
    // 获取所有操作人
    getUsersList({ commit, state }, payload) {
      queryUsersList(payload).then((res) => {
        commit({ type: 'setState', key: 'allUsersList', values: res.data.data })
      })
    },
    // 获取发放面值
    getPackageTypeList({ commit, state }, payload) {
      queryPackageType(payload).then((res) => {
        commit({ type: 'setState', key: 'packageType', values: res.data })
      })
    },
    // 获取订单的关联订单信息
    getAssociatedDetail({ commit, state }, payload) {
      return queryAssociatedDetail(payload)
    },
  },
}
export default common
