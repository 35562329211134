import Vue from 'vue'
import Vuex from 'vuex'
import { creatRetails, queryRetails, editRetails } from '@/api/commodity'
Vue.use(Vuex)
export default {
  namespaced: true,
  state: {
    commodityListVal: {}, // 商品列表搜索条件
  },
  mutations: {
    setState(state, payload) {
      state[payload.key] = payload.values
    },
  },
  actions: {
    changeCommodityListSearch({ commit }, payload) {
      commit({ type: 'setState', key: 'commodityListVal', values: payload })
    },
    // 添加商品
    addRetails({ commit, state }, payload) {
      return creatRetails(payload)
    },
    // 查询商品
    getRetails({ commit, state }, payload) {
      return queryRetails(payload)
    },
    // 编辑商品
    changeRetails({ commit, state }, payload) {
      return editRetails(payload)
    },
  },
}
