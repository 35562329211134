import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const cost = {
  namespaced: true,
  state: {
    searchVal: '',
    logStatus: 'loginLog',
    logIndex: 0,
  },
  mutations: {
    setState(state, payload) {
      state[payload.key] = payload.values
    },
  },
  actions: {
    changeSearch({ commit }, payload) {
      commit({ type: 'setState', key: 'searchVal', values: payload.values })
    },
    changeLogStatus({ commit }, payload) {
      commit({ type: 'setState', key: 'logStatus', values: payload.values })
    },
    changeLogIndex({ commit }, payload) {
      commit({ type: 'setState', key: 'logIndex', values: payload.values })
    },
  },
}
export default cost
