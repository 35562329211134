import { get, post, patch } from '@/utils/request'
import globalFunction from '@/utils/globalFunction'

// 出行券列表
export const tripCouponStocksList = (params) => {
  return get(`/commons/trip_coupon_stocks?${globalFunction.paramsFormat(params)}`)
}
// 添加出行券库存信息
export const creatTripCouponStocks = (params) => {
  return post('/commons/trip_coupon_stocks', params)
}
// 查询出行券库存信息
export const queryTripCouponStocks = (params) => {
  return get(`/commons/trip_coupon_stocks/${params}`)
}
// 修改出行券库存信息
export const editTripCouponStocks = (params) => {
  return patch(`/commons/trip_coupon_stocks/${params.id}`, params.data)
}
