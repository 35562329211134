import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { constantRouterMap, asyncRouterMap } from './component'
import { filterAsyncRouter } from '@/utils/filterRouter'
Vue.use(VueRouter)

// constantRouterMap.map((item) => {
//   item.meta = {
//     title: item.title,
//   }
//   return item
// })

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: constantRouterMap,
  })

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

// 获取父菜单
function getParent(to) {
  const matchedPath = to.matched.find((element) => element.path === to.path)
  if (matchedPath) {
    return matchedPath.parent
  }
  return null
}

// 是否有菜单数据
let hasMenus = false
const defaultTitle = '博派车管家综合管理平台'
router.beforeEach(async (to, from, next) => {
  const parent = getParent(to)
  const parentTitle = parent && parent.meta.title ? ' · ' + parent.meta.title : ''

  document.title = to.meta.title ? to.meta.title + parentTitle + ' · ' + defaultTitle : defaultTitle
  // 有 token
  if (localStorage.getItem('token')) {
    if (to.path === '/login') {
      next({ path: '/' })
    } else if (hasMenus) {
      if (to.name == null) {
        next('/404')
      } else {
        next()
      }
    } else {
      try {
        // 这里可以用 await 配合请求后台数据来生成路由
        // const data = await axios.get('xxx')
        // const routes = createRoutes(data)

        const menu = JSON.parse(localStorage.getItem('addRoutes'))
        const routes = filterAsyncRouter(asyncRouterMap, menu)
        // 动态添加路由
        router.addRoutes(routes)

        store.commit({
          type: 'login/setState',
          key: 'addRoutes',
          values: routes,
        })

        hasMenus = true
        next({ path: to.path || '/' })
      } catch (error) {
        console.log(error)
        // logout();
        // next(`/login?redirect=${to.path}`);
      }
    }
  } else {
    hasMenus = false
    if (to.path === '/login') {
      next()
    } else {
      next('/login')
      // next(`/login?redirect=${to.path}`);
    }
  }
})
router.onError((error) => {
  console.error(error)
  const pattern = /Loading chunk/g
  // const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed && error.type === 'missing') {
    // const targetPath = $router.history.pending.fullPath
    router.push(targetPath)
  }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}
export default router
