import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const cost = {
  namespaced: true,
  state: {
    tabIndex: 0,
    searchVal: {},
  },
  mutations: {
    setState(state, payload) {
      state[payload.key] = payload.values
    },
  },
  actions: {
    changeTabIndex({ commit }, payload) {
      commit({ type: 'setState', key: 'tabIndex', values: payload.tabIndex })
    },
    changeSearch({ commit }, payload) {
      commit({ type: 'setState', key: 'searchVal', values: payload.values })
    },
  },
}
export default cost
