import Vue from 'vue'
import Vuex from 'vuex'
import {
  queryRepairsOrderDetail,
  queryMemorandumsDetail,
  creatMemorandumsApi,
  repairsCarrierApi,
  queryAccessInfo,
  queryAuditsApi,
  operationsApi,
  cancelCarriersApi,
  queryCarriersLogs,
  queryCarriersTrack,
  sendSmsApi,
  queryCarriersDistanceApi,
} from '@/api/repairs'
import { queryDriverList } from '@/api/system/index'
Vue.use(Vuex)
const repairs = {
  namespaced: true,
  state: {},
  mutations: {
    setState(state, payload) {
      state[payload.key] = payload.values
    },
  },
  actions: {
    // 获取事故车订单详情
    getRepairsOrderDetail({ commit, state }, payload) {
      return queryRepairsOrderDetail(payload)
    },
    // 获取订单备忘录信息
    getMemorandumsDetail({ commit, state }, payload) {
      return queryMemorandumsDetail(payload)
    },
    // 添加备忘录
    creatMemorandums({ commit, state }, payload) {
      return creatMemorandumsApi(payload)
    },
    // 创建代驾订单
    creatRepairsCarrierApi({ commit, state }, payload) {
      return repairsCarrierApi(payload)
    },
    // 查看明文手机号
    getAccessInfo({ commit, state }, payload) {
      return queryAccessInfo(payload)
    },
    // 查看审核记录
    getAudits({ commit, state }, payload) {
      return queryAuditsApi(payload)
    },
    // 订单操作(审核通过:approve,审核未通过:reject,取消订单:cancel)
    operations({ commit, state }, payload) {
      return operationsApi(payload)
    },
    // 取消代驾订单
    cancelCarriers({ commit, state }, payload) {
      return cancelCarriersApi(payload)
    },
    // 查看代驾下单记录
    getCarriersLogs({ commit, state }, payload) {
      return queryCarriersLogs(payload)
    },
    // 查看代驾信息
    getCarriersTrack({ commit, state }, payload) {
      return queryCarriersTrack(payload)
    },
    // 重发短信
    sendSms({ commit, state }, payload) {
      return sendSmsApi(payload)
    },
    // 获取代驾列表
    getDriverList({ commit, state }, payload) {
      return queryDriverList(payload)
    },
    // 获取驾车距离和e代驾id
    getCarriersDistance({ commit, state }, payload) {
      return queryCarriersDistanceApi(payload)
    },
  },
}

export default repairs
