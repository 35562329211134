<template>
  <div v-show="show" class="loading">
    <div class="loading-card">
      <div class="loading-container">
        <v-img
          max-height="30"
          max-width="30"
          src="../../assets/img/toast/webDesign_Loading.gif"
        ></v-img>
        <span class="loading-text">{{ text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingComponent',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(21, 21, 21, 0.7);
}
.loading-card {
  background: #ffffff;
  border-radius: 2px;
  width: 400px;
  min-width: 400px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading-container {
  height: 100px;
  color: #333333;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-text {
  font-size: 16px;
  color: #333333;
  font-weight: 500;
  margin-left: 10px;
}
</style>
