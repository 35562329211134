<template>
  <v-dialog v-model="dialog" width="500px" light persistent>
    <v-card>
      <v-card-title class="d-flex justify-center">
        <div>{{ content }}</div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="goLogin">去登录</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
      content: '',
      vue: null,
    }
  },
  created() {
    this.$vuetify = this.vue.$vuetify
  },
  methods: {
    goLogin() {
      this.dialog = false
      localStorage.clear()
      this.vue.$router.replace('/login')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  border-radius: 16px !important;
}
.v-dialog > .v-card > .v-card__title {
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0125em;
  padding-top: 34px;
  padding-bottom: 34px;
}
</style>
