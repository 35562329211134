var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "loading",
    },
    [
      _c("div", { staticClass: "loading-card" }, [
        _c(
          "div",
          { staticClass: "loading-container" },
          [
            _c("v-img", {
              attrs: {
                "max-height": "30",
                "max-width": "30",
                src: require("../../assets/img/toast/webDesign_Loading.gif"),
              },
            }),
            _c("span", { staticClass: "loading-text" }, [
              _vm._v(_vm._s(_vm.text)),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }