import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router/index'
// import router from '../router/index'
// import { treeToList } from '@/utils/treeToList.js'
// import { constantRouterMap, asyncRouterMap } from '@/router/component.js'
import { userLogin, queryRoutes } from '@/api/login'

Vue.use(Vuex)
const login = {
  namespaced: true,
  state: {
    // 菜单
    menu: [],
    addRoutes: [],
  },
  mutations: {
    setState(state, payload) {
      state[payload.key] = payload.values
    },
  },
  actions: {
    // getMenuList({ commit }) {
    //   let arr = constantRouterMap.filter(items => {
    //     return !items.hidden
    //   })
    //   commit({ type: "setState", key: 'menu', values: arr[0].children })
    // },
    // 登录
    Login({ commit, dispatch }, payload) {
      return userLogin(payload).then((res) => {
        localStorage.setItem('refresh', res.data.refresh)
        localStorage.setItem('token', `AutoButler ${res.data.access}`)
        localStorage.setItem('username', res.data.username)
        localStorage.setItem('name', res.data.name)
        localStorage.setItem('userId', res.data.id)
        dispatch('getRoutes')
      })
    },
    // 获取路由信息
    getRoutes({ commit, state }) {
      return queryRoutes()
        .then((res) => {
          const menus = res.data
          localStorage.setItem('addRoutes', JSON.stringify(menus))
          router.push('/')
        })
        .catch(() => {
          localStorage.removeItem('token')
          router.push('/')
          // router.replace("/login");
        })
    },
  },
}
export default login
