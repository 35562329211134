import Vue from 'vue'
import Vuex from 'vuex'
import { queryCouponList, bulkSeliveryApi, downloadReasonApi } from '@/api/equities'

Vue.use(Vuex)
const equities = {
  namespaced: true,
  state: {
    couponSearchVal: {}, // 打车券搜索条件
  },
  mutations: {
    setState(state, payload) {
      state[payload.key] = payload.values
    },
  },
  actions: {
    changeCouponSearchVal({ commit }, payload) {
      commit({ type: 'setState', key: 'couponSearchVal', values: payload.values })
    },
    // 获取打车券列表
    getCouponList({ commit, state }, payload) {
      return queryCouponList(payload)
    },
    // 批量发券
    bulkSelivery({ commit, state }, payload) {
      return bulkSeliveryApi(payload)
    },
    // 下载失败原因
    downloadReason({ commit, state }, payload) {
      return downloadReasonApi(payload)
    },
  },
}

export default equities
